import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReactComponent as BookIcon } from "../svg/book.svg";
import { ReactComponent as QuestionCircle } from "../svg/question_circle.svg";
import { ReactComponent as DoubleDownArrow } from "../svg/double_down_arrow.svg";
import { ReactComponent as HalfLeftUpArrow } from "../svg/half_left_up_arrow.svg";
import { ReactComponent as MobileHalfLeftUpArrow } from "../svg/mobile_half_left_up_arrow.svg";
import { ReactComponent as LeftArrow } from "../svg/left_arrow.svg";
import { ReactComponent as RightArrow } from "../svg/right_arrow.svg";
import { ReactComponent as InterviewLogo } from "../svg/interview_logo.svg";
import { ReactComponent as HomeArrowPointer } from "../svg/home_arrow_pointer.svg";
import { ReactComponent as CartIconFilled } from "../svg/cart_icon_filled.svg";
import { ReactComponent as CartIconNonFilled } from "../svg/cart_icon_non_filled.svg";
import { ReactComponent as VoteIcon } from "../svg/vote_icon.svg";
import "./Home.scss";
import { Dialog, Fade, Grid, Hidden, IconButton } from "@mui/material";
import HomeSwiper, {
  BannerSwiper,
  SwiperPagination,
} from "./customs/HomeSwiper";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation } from "swiper";
import {
  fetchDefaultWithCredential,
  formatProgramTime,
  getWeekDay,
  isMobile,
} from "../utils";
import { ProgramStatus } from "./Program";
import { useHistory, useLocation } from "react-router-dom";
import { TutorStatus } from "./admins/pages/users/Review";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import queryString from "query-string";
import {
  GlobalContext,
  SnackbarContext,
} from "../globals/components/ComponentsWrapper";
import moment from "moment";
import { patchPdfDownloaded } from "./Cart";
SwiperCore.use([Navigation]);

const windowWidth = window.innerWidth;
const containerWidth =
  windowWidth >= 1920 ? windowWidth - 320 : Math.min(1600, windowWidth);

export const textColor = "#111111";

export function widthPercentage(percentage: number) {
  return (containerWidth * percentage) / 100;
}

export function responsivePixel(pixel: number) {
  if (isMobile()) {
    return pixel * 0.75;
  }
  return pixel;
}

export function responsivePercentage(percentage: number) {
  if (isMobile()) {
    return `${50 + percentage / 2}%`;
  }
  return `${percentage}%`;
}

export const customWidthPercentage = (
  customContainerWidth: number,
  percentage: number
) => {
  return (customContainerWidth * widthPercentage(percentage)) / containerWidth;
};

function BannerComponent() {
  const { homeData } = useContext(HomeContext);
  return (
    <Fragment>
      <BannerSwiper>
        {homeData.banners.map((banner, index) => (
          <div
            key={index}
            style={{
              height: isMobile()
                ? widthPercentage(153.75)
                : widthPercentage(28.75),
              backgroundImage: `url(${
                isMobile() ? banner.mobile_image : banner.image
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor: banner.link !== "" ? "pointer" : "inherit",
            }}
            onClick={() => {
              if (banner.link !== "") {
                window.open(banner.link, "_blank");
              }
            }}
          />
        ))}
      </BannerSwiper>
    </Fragment>
  );
}

function InfoInitComponent() {
  return (
    <HomeContainer>
      <Hidden smDown>
        <div style={{ marginTop: widthPercentage(1.25) }}>
          <div className="d-flex justify-content-space-between align-items-center p-relative">
            <div className="d-flex align-items-center p-relative">
              <div
                style={{
                  width: widthPercentage(2),
                  height: widthPercentage(2),
                }}
              >
                <BookIcon style={{ width: "100%", height: "100%" }} />
              </div>
              <div
                className="font-noto-medium"
                style={{
                  marginLeft: widthPercentage(0.25),
                  fontSize: widthPercentage(1.125),
                  color: textColor,
                  userSelect: "none",
                }}
              >
                필로어스의 프로그램과 컨텐츠를 둘러보세요
              </div>
              <div
                className="p-absolute"
                style={{
                  bottom: -widthPercentage(0.5),
                  background: "black",
                  width: "100%",
                  height: "1px",
                  zIndex: -7,
                }}
              />
            </div>
            <div
              className="d-flex align-items-center info-init-brand cursor-pointer"
              onClick={() => window.open("/brand", "_blank")}
            >
              <div
                style={{
                  width: widthPercentage(2),
                  height: widthPercentage(2),
                }}
              >
                <QuestionCircle style={{ width: "100%", height: "100%" }} />
              </div>
              <div
                style={{
                  marginLeft: widthPercentage(0.25),
                  fontSize: widthPercentage(1.125),
                  userSelect: "none",
                }}
              >
                필로어스에 대해 더 알아보고 싶다면?
              </div>
            </div>
            <div
              className="p-absolute"
              style={{
                bottom: -widthPercentage(0.5),
                background: "#E5E5EC",
                width: "100%",
                height: "1px",
                zIndex: -8,
              }}
            />
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div style={{ marginTop: widthPercentage(3) }}>
          <Grid container className="p-relative">
            <Grid item xs>
              <div
                style={{
                  paddingBottom: widthPercentage(3.5),
                }}
              >
                <div
                  style={{
                    width: widthPercentage(8),
                    height: widthPercentage(8),
                    margin: "0px auto",
                  }}
                >
                  <BookIcon style={{ width: "100%", height: "100%" }} />
                </div>
                <div
                  className="font-noto-medium"
                  style={{
                    fontSize: widthPercentage(4.5),
                    color: textColor,
                    userSelect: "none",
                    textAlign: "center",
                    marginTop: widthPercentage(0.5),
                  }}
                >
                  프로그램 및 컨텐츠
                </div>
              </div>
              <div
                style={{
                  zIndex: -7,
                  width: "100%",
                  height: widthPercentage(0.25),
                  backgroundColor: "black",
                }}
              ></div>
            </Grid>
            <Grid item xs>
              <div
                className="info-init-brand cursor-pointer"
                style={{
                  paddingBottom: widthPercentage(3.5),
                }}
                onClick={() => window.open("/brand", "_blank")}
              >
                <div
                  style={{
                    width: widthPercentage(8),
                    height: widthPercentage(8),
                    margin: "0px auto",
                  }}
                >
                  <QuestionCircle style={{ width: "100%", height: "100%" }} />
                </div>
                <div
                  style={{
                    fontSize: widthPercentage(4.5),
                    userSelect: "none",
                    textAlign: "center",
                    marginTop: widthPercentage(0.5),
                  }}
                >
                  필로어스 소개
                </div>
              </div>
              <div
                style={{
                  zIndex: -7,
                  width: "100%",
                  height: widthPercentage(0.25),
                  backgroundColor: "#E5E5EC",
                }}
              ></div>
            </Grid>
          </Grid>
        </div>
      </Hidden>
    </HomeContainer>
  );
}

interface HomeContainerProps {
  children: ReactNode;
  full?: boolean;
}

export function HomeContainer({ children, full }: HomeContainerProps) {
  return (
    <div
      className="margin-center"
      style={{
        width: isMobile()
          ? widthPercentage(full ? 100 : 92)
          : widthPercentage(full ? 100 : 82),
      }}
    >
      {children}
    </div>
  );
}

function MainLetterComponent() {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [whiteRef, setWhiteRef] = useState<HTMLDivElement | null>(null);
  const [backgroundHeight, setBackgroundHeight] = useState<number>(0);
  const [hovered, setHovered] = useState<boolean>(false);
  const [firstFadeDone, setFirstFadeDone] = useState<boolean>(false);
  const [secondFadeDone, setSecondFadeDone] = useState<boolean>(false);

  useEffect(() => {
    if (containerRef && whiteRef) {
      setBackgroundHeight(containerRef.clientHeight - whiteRef.clientHeight);
    }
  }, [containerRef, whiteRef]);

  return (
    <div
      style={{
        height: containerRef?.clientHeight ?? 0,
      }}
    >
      <div
        style={{
          background: hovered ? "#5e10ac" : "inherit",
          height: backgroundHeight,
          userSelect: "none",
        }}
      >
        <div ref={setContainerRef}>
          <HomeContainer>
            <Hidden smDown>
              <div
                style={{
                  marginTop: widthPercentage(0.5) - 1,
                  paddingTop: widthPercentage(5),
                  zIndex: -3,
                }}
              >
                <div className="d-flex justify-content-flex-end">
                  <div
                    style={{
                      width: widthPercentage(30),
                      fontSize: widthPercentage(1),
                      color: hovered ? "white" : "#767676",
                      textAlign: "right",
                      top: 0,
                      right: 0,
                      zIndex: 10,
                    }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    {!hovered && (
                      <Fragment>
                        혼자 읽기 어려운 책을 함께 읽고,
                        <br />
                        나만의 질문을 발견해 글을 쓰고,
                        <br />
                        가족, 친구와는 나누기 어려운{" "}
                        <span className="font-bold">
                          ‘깊은 철학적 대화 모임’
                        </span>
                        을 경험해보세요.
                      </Fragment>
                    )}
                    {hovered && (
                      <Fragment>
                        인문고전 독서모임 필로어스에서
                        <br />
                        고전 첫 만남부터 100권까지, 함께 읽고 토론해요.
                        <br />
                        철학하는 방법을 배우고, 세상을 바라보는 나만의 시각을
                        길러보세요.
                      </Fragment>
                    )}
                  </div>
                </div>
                <div
                  className="font-noto-black"
                  style={{
                    height: ref
                      ? ref.offsetHeight + widthPercentage(-1.5 - 8.375 - 0.625)
                      : 0,
                  }}
                >
                  <div
                    className="p-relative"
                    style={{
                      top: widthPercentage(-1.5),
                      fontSize: widthPercentage(9.375),
                      fontWeight: 900,
                      color: hovered ? "white" : textColor,
                    }}
                    ref={setRef}
                  >
                    <Fade
                      in
                      timeout={500}
                      addEndListener={() => {
                        const timeout = 250;
                        setTimeout(() => {
                          setFirstFadeDone(true);
                          setTimeout(() => setSecondFadeDone(true), timeout);
                        }, timeout);
                      }}
                    >
                      <span
                        className="p-relative"
                        onMouseOver={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        style={{ zIndex: 1 }}
                      >
                        고전,
                      </span>
                    </Fade>
                    <div
                      className="p-relative text-align-end"
                      style={{
                        top: widthPercentage(-8.375),
                      }}
                    >
                      <div
                        className="p-relative"
                        style={{
                          fontSize: widthPercentage(7.5),
                          fontWeight: 900,
                          color: hovered ? "transparent" : "white",
                          WebkitTextStrokeColor: hovered ? "white" : textColor,
                          WebkitTextStrokeWidth: 1,
                        }}
                      >
                        <div className="p-relative d-flex justify-content-flex-end">
                          <div className="p-relative">
                            <div
                              onMouseOver={() => setHovered(true)}
                              onMouseLeave={() => setHovered(false)}
                            >
                              {hovered && (
                                <div
                                  className="p-absolute"
                                  style={{
                                    top: widthPercentage(-0.25),
                                    left: widthPercentage(0.5),
                                    fontWeight: "normal",
                                  }}
                                >
                                  <div className="p-relative">
                                    <div
                                      className="font-noto-light"
                                      style={{
                                        fontSize: widthPercentage(0.875),
                                        color: "white",
                                        transform: "rotate(-2deg)",
                                        background: "#EA1A1A",
                                      }}
                                    >
                                      필로어스가 더 알고싶어요!
                                    </div>
                                    <div
                                      className="p-absolute"
                                      style={{
                                        bottom: widthPercentage(-0.9375),
                                        width: widthPercentage(44),
                                        height: widthPercentage(15.625),
                                      }}
                                    >
                                      <HomeArrowPointer
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <Fade in={firstFadeDone} timeout={500}>
                                <span style={{ fontFamily: "Roboto-Medium" }}>
                                  가장 트렌디하게
                                </span>
                              </Fade>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          top: widthPercentage(-0.625),
                          fontSize: widthPercentage(7.5),
                          fontWeight: 900,
                          color: textColor,
                        }}
                        ref={setWhiteRef}
                      >
                        <Fade in={secondFadeDone} timeout={500}>
                          <span
                            onMouseOver={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                          >
                            주 1회 철학습관
                          </span>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Hidden>
            <Hidden smUp>
              <div
                style={{
                  marginTop: -widthPercentage(0.25),
                  paddingTop: widthPercentage(5),
                  zIndex: -3,
                }}
              >
                <div className="font-noto-black">
                  <div
                    style={{
                      marginTop: widthPercentage(11),
                      fontSize: widthPercentage(14),
                      fontWeight: 900,
                      color: textColor,
                    }}
                    ref={setRef}
                  >
                    <Fade
                      in
                      timeout={500}
                      addEndListener={() => {
                        const timeout = 250;
                        setTimeout(() => {
                          setFirstFadeDone(true);
                          setTimeout(() => setSecondFadeDone(true), timeout);
                        }, timeout);
                      }}
                    >
                      <span className="p-relative" style={{ zIndex: 1 }}>
                        고전,
                      </span>
                    </Fade>
                    <div
                      style={{
                        marginTop: -widthPercentage(2),
                      }}
                    >
                      <div
                        className="p-relative"
                        style={{
                          fontSize: widthPercentage(13),
                          fontWeight: 900,
                          color: "white",
                          WebkitTextStrokeColor: textColor,
                          WebkitTextStrokeWidth: widthPercentage(0.25),
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Fade in={firstFadeDone} timeout={500}>
                          <span style={{ fontFamily: "Roboto-Medium" }}>
                            가장 트렌디하게
                          </span>
                        </Fade>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: -widthPercentage(2),
                      }}
                    >
                      <div className="p-relative">
                        <div
                          style={{
                            fontSize: widthPercentage(13),
                            fontWeight: 900,
                            color: textColor,
                            whiteSpace: "nowrap",
                          }}
                          ref={setWhiteRef}
                        >
                          <Fade in={secondFadeDone} timeout={500}>
                            <span>주 1회 철학습관</span>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-flex-end">
                  <div
                    style={{
                      marginTop: widthPercentage(11),
                      width: widthPercentage(80),
                      fontSize: widthPercentage(4),
                      color: "#767676",
                      zIndex: 10,
                    }}
                  >
                    혼자 읽기 어려운 책을 함께 읽고,
                    <br />
                    나만의 질문을 발견해 글을 쓰고,
                    <br />
                    가족, 친구와는 나누기 어려운
                    <br />
                    <span className="font-bold">‘깊은 철학적 대화 모임’</span>을
                    경험해보세요.
                  </div>
                </div>
              </div>
            </Hidden>
          </HomeContainer>
        </div>
      </div>
    </div>
  );
}

interface ProgramCardImageComponentProps {
  index: number;
  program_image?: string;
  width?: number;
  badge?: string;
  blur?: boolean;
  blurText?: string;
  votes?: number;
}

export function getBackgroundShadowColors(index: number) {
  let backgroundColor = "";
  let shadowColor = "";
  if (index === 0) {
    backgroundColor = "#D4D0FE";
    shadowColor = "rgba(78, 0, 70, 0.15)";
  } else if (index === 1) {
    backgroundColor = "#EA7800";
    shadowColor = "rgba(111, 0, 0, 0.15)";
  } else if (index === 2) {
    backgroundColor = "#009FC1";
    shadowColor = "rgba(48, 0, 56, 0.15)";
  } else if (index === 3) {
    backgroundColor = "#FFD66B";
    shadowColor = "rgba(48, 0, 56, 0.15)";
  } else {
    backgroundColor = "#F1F1FA";
    shadowColor = "rgba(78, 0, 70, 0.15)";
  }
  return {
    backgroundColor,
    shadowColor,
  };
}

export function ProgramCardImageComponent({
  index,
  program_image,
  width,
  badge,
  blur,
  blurText,
  votes,
}: ProgramCardImageComponentProps) {
  const { backgroundColor, shadowColor } = getBackgroundShadowColors(index);

  return (
    <div
      className="p-relative"
      style={{
        width: width ?? "100%",
        height: isMobile() ? widthPercentage(42.75) : widthPercentage(12.5),
        backgroundColor,
        overflow: "hidden",
      }}
    >
      {blur && (
        <div
          style={{
            position: "absolute",
            inset: 0,
            zIndex: 1,
            backdropFilter: `blur(${widthPercentage(isMobile() ? 5 : 1.25)}px)`,
            WebkitBackdropFilter: `blur(${widthPercentage(
              isMobile() ? 5 : 1.25
            )}px)`,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          }}
        ></div>
      )}
      {badge && (
        <div
          className="p-absolute d-flex justify-content-center align-items-center"
          style={{
            top: widthPercentage(isMobile() ? 2 : 0.75),
            left: widthPercentage(isMobile() ? 2 : 0.75),
            width: widthPercentage(isMobile() ? 11 : 2.75),
            height: widthPercentage(isMobile() ? 11 : 2.75),
            fontSize: widthPercentage(isMobile() ? 3 : 0.75),
            color: textColor,
            backgroundColor: "#FFD66B",
            borderRadius: "50%",
          }}
        >
          {badge}
        </div>
      )}
      <div
        className="p-absolute"
        style={{
          width: isMobile() ? widthPercentage(30) : widthPercentage(8.75),
          height: isMobile() ? widthPercentage(42.75) : widthPercentage(12.5),
          transform: "translateX(-50%)",
          top: isMobile() ? widthPercentage(7.5) : widthPercentage(2.125),
          left: "50%",
          ...(() => {
            if (program_image === undefined) {
              return {
                backgroundColor: "#181414",
              };
            }
            return {
              backgroundImage: `url(${program_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            };
          })(),
          filter: `drop-shadow(${widthPercentage(0.875)}px ${widthPercentage(
            0.75
          )}px ${widthPercentage(1.25)}px rgba(159, 0, 0, 0.16))`,
        }}
      >
        <div className="p-relative">
          <div
            className="p-absolute"
            style={{
              left: "100%",
              borderLeft: `${
                isMobile() ? widthPercentage(40) : widthPercentage(10)
              }px solid ${shadowColor}`,
              borderTop: `${
                isMobile() ? widthPercentage(40) : widthPercentage(10)
              }px solid transparent`,
              borderBottom: `${
                isMobile() ? widthPercentage(40) : widthPercentage(10)
              }px solid ${shadowColor}`,
            }}
          ></div>
        </div>
      </div>
      {blur && (
        <div
          className="p-absolute"
          style={{
            width: "100%",
            height: isMobile() ? widthPercentage(42.75) : widthPercentage(12.5),
            top: isMobile() ? widthPercentage(3.75) : widthPercentage(1.0625),
            textAlign: "center",
            zIndex: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              className="font-noto-medium"
              style={{
                fontWeight: 700,
                fontSize: widthPercentage(isMobile() ? 4.5 : 1.125),
                color: "white",
              }}
            >
              {blurText}
              <div className="d-flex justify-content-center">
                <div
                  style={{
                    marginTop: widthPercentage(isMobile() ? 2 : 0.5),
                    width: widthPercentage(isMobile() ? 14.5 : 3.625),
                    height: widthPercentage(isMobile() ? 0.5 : 0.125),
                    backgroundColor: "white",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {votes !== undefined && (
        <div
          className="p-absolute"
          style={{
            padding: `${widthPercentage(
              isMobile() ? 3.25 : 0.8125
            )}px ${widthPercentage(isMobile() ? 4 : 1)}px`,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: widthPercentage(isMobile() ? 4 : 1),
              height: widthPercentage(isMobile() ? 4 : 1),
              display: "flex",
            }}
          >
            <VoteIcon style={{ width: "100%", height: "100%" }} />
          </div>
          <div
            className="font-roboto-regular"
            style={{
              marginLeft: widthPercentage(isMobile() ? 0.5 : 0.125),
              color: "white",
              fontSize: widthPercentage(isMobile() ? 3.75 : 0.9375),
            }}
          >
            {votes.toLocaleString()}
          </div>
        </div>
      )}
    </div>
  );
}

interface ProgramCardProps {
  width: number;
  program: RecrutingProgram | null;
  index: number;
  review?: HomeReview;
  badge?: string;
  cart?: boolean;
  blur?: boolean;
  blurText?: string;
  customDescriptionBox?: ReactNode;
  disablePointer?: boolean;
  vote?: boolean;
}

export function ProgramCard({
  program,
  index,
  review,
  width,
  badge,
  cart,
  blur,
  blurText,
  customDescriptionBox,
  disablePointer,
  vote,
}: ProgramCardProps) {
  const [votes, setVotes] = useState<number>(0);
  const history = useHistory();
  const [inCart, setInCart] = useState<boolean>(Boolean(cart));
  const { signinWrapper } = useContext(GlobalContext);
  const { showSuccessSnackbar, showWarningSnackbar } =
    useContext(SnackbarContext);
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    if (review) {
      setVotes(review.votes);
    }
  }, [review]);

  const patchProgramCart = () => {
    if (!program) return;
    fetchDefaultWithCredential(`/program/${program.id}/cart`, "PATCH").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => {
          if (!inCart) {
            showSuccessSnackbar("장바구니에 담겼습니다");
          } else {
            showWarningSnackbar("장바구니에서 제거되었습니다");
          }
          setInCart(!inCart);
        });
      }
    );
  };

  return (
    <Fragment>
      <div
        style={{
          border: "1px solid #E5E5EC",
          cursor: disablePointer ? "default" : "pointer",
          width,
          boxSizing: "border-box",
        }}
        onClick={() => {
          if (!program || disablePointer) return;
          if (!review) {
            history.push("/program/" + program.id);
          } else {
            parsed.review_id = review.program_review_id.toString();
            parsed.index = index.toString();
            location.search = queryString.stringify(parsed);
            history.replace({ search: location.search });
          }
        }}
      >
        <ProgramCardImageComponent
          index={index}
          program_image={program?.image}
          badge={badge}
          blur={blur}
          blurText={blurText}
          votes={(() => {
            if (!vote || !review) return;
            return votes;
          })()}
        />
        <div
          style={{
            padding: isMobile()
              ? `${widthPercentage(5.5)}px ${widthPercentage(6.5)}px`
              : `${widthPercentage(1.75)}px ${widthPercentage(1.5)}px`,
            position: "relative",
          }}
        >
          {customDescriptionBox}
          {!customDescriptionBox && (
            <Fragment>
              <div
                style={{
                  fontSize: isMobile()
                    ? widthPercentage(4)
                    : widthPercentage(0.9375),
                  color: "#505050",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {!program && "PhiloUs"}
                {program && program.program_name}
              </div>
              {!review && (
                <Fragment>
                  <div
                    className="font-noto-medium"
                    style={{
                      fontSize: isMobile()
                        ? widthPercentage(4)
                        : widthPercentage(0.9375),
                      color: textColor,
                      marginTop: isMobile()
                        ? widthPercentage(0.5)
                        : widthPercentage(0.125),
                    }}
                  >
                    {!program && "곧 오픈됩니다! :-)"}
                    {program && program.program_description}
                  </div>
                  {program && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        signinWrapper(patchProgramCart);
                      }}
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        right: widthPercentage(isMobile() ? 4 : 1),
                        top: 0,
                        marginTop: -widthPercentage(isMobile() ? 0.5 : 0.125),
                        width: widthPercentage(isMobile() ? 13 : 3.25),
                        height: widthPercentage(isMobile() ? 13 : 3.25),
                        background: "white",
                        boxShadow: `0px ${widthPercentage(
                          isMobile() ? 0.25 : 0.0625
                        )}px ${widthPercentage(
                          isMobile() ? 1 : 0.25
                        )}px rgba(0, 0, 0, 0.2)`,
                        borderRadius: `0px 0px ${widthPercentage(
                          isMobile() ? 6 : 1.5
                        )}px ${widthPercentage(isMobile() ? 6 : 1.5)}px`,
                        padding: `0 ${widthPercentage(
                          isMobile() ? 2.5 : 0.625
                        )}px`,
                        paddingTop: widthPercentage(isMobile() ? 2 : 0.5),
                        zIndex: 1,
                      }}
                    >
                      <div
                        style={{
                          width: widthPercentage(isMobile() ? 8 : 2),
                          height: widthPercentage(isMobile() ? 8 : 2),
                        }}
                      >
                        {!inCart && (
                          <CartIconNonFilled
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        )}
                        {inCart && (
                          <CartIconFilled
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
              {review && (
                <div
                  className="font-noto-regular"
                  style={{
                    marginTop: isMobile()
                      ? widthPercentage(2.5)
                      : widthPercentage(0.625),
                    color: "#767676",
                    fontSize: isMobile()
                      ? widthPercentage(3.5)
                      : widthPercentage(0.875),
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    cursor: "pointer",
                    height: isMobile()
                      ? widthPercentage(15)
                      : widthPercentage(3.75),
                  }}
                >
                  {review.program_review_detail}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

function TabComponent() {
  const [index, setIndex] = useState<number>(0);
  const { guideContainerRef, homeData, currentYOffset, tabScrollYOffset } =
    useContext(HomeContext);

  const tabList = homeData.programs.map((program) => program.program_name);

  useEffect(() => {
    if (tabScrollYOffset.length < 1) return;
    let set = false;
    for (let i = 1; i < tabScrollYOffset.length; i++) {
      const midYOffset = (tabScrollYOffset[i - 1] + tabScrollYOffset[i]) / 2;
      if (currentYOffset < midYOffset) {
        setIndex(i - 1);
        set = true;
        break;
      }
    }
    if (!set) {
      setIndex(tabScrollYOffset.length - 1);
    }
  }, [tabScrollYOffset, currentYOffset]);

  return (
    <Fragment>
      <Hidden smDown>
        <div
          style={{
            height: widthPercentage(2.5),
            boxSizing: "border-box",
          }}
        >
          <div className="d-flex">
            <div className="d-flex" style={{ height: "100%", flexGrow: 1 }}>
              {tabList.map((tab, tabIndex) => (
                <div
                  className={
                    index === tabIndex
                      ? "font-noto-medium"
                      : "font-noto-regular"
                  }
                  key={tabIndex}
                  style={{
                    color: index === tabIndex ? textColor : "#999999",
                    fontSize: widthPercentage(1),
                    borderBottom:
                      index === tabIndex
                        ? `${widthPercentage(0.125)}px solid black`
                        : undefined,
                    marginRight: widthPercentage(2.5),
                    paddingBottom: widthPercentage(0.90625),
                    cursor: "pointer",
                    height: "100%",
                    fontWeight: index === tabIndex ? 500 : 400,
                    userSelect: "none",
                  }}
                  onClick={() => {
                    window.scrollTo({
                      left: 0,
                      top: tabScrollYOffset[tabIndex],
                      behavior: "smooth",
                    });
                  }}
                >
                  {tab}
                </div>
              ))}
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={() =>
                window.scrollTo({
                  left: 0,
                  top: guideContainerRef?.offsetTop ?? 0,
                  behavior: "smooth",
                })
              }
            >
              <div
                className="d-flex"
                style={{
                  padding: widthPercentage(0.25),
                }}
              >
                <DoubleDownArrow
                  style={{
                    width: widthPercentage(1),
                    height: widthPercentage(1),
                  }}
                />
              </div>
              <div
                className="home-hover-font-zoom"
                style={{
                  color: "#4A148C",
                  fontSize: widthPercentage(1),
                }}
              >
                고전, 어떻게 읽나요?
              </div>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="p-relative">
          <div
            style={{
              overflow: "auto",
              paddingTop: widthPercentage(4),
              height: widthPercentage(13.5),
              minWidth: "100%",
              whiteSpace: "nowrap",
            }}
          >
            {tabList.map((tab, tabIndex) => (
              <div
                className={
                  index === tabIndex ? "font-noto-medium" : "font-noto-regular"
                }
                key={tabIndex}
                style={{
                  boxSizing: "border-box",
                  display: "inline-block",
                  color: index === tabIndex ? textColor : "#999999",
                  fontSize: widthPercentage(4),
                  borderBottom:
                    index === tabIndex
                      ? `${widthPercentage(0.25)}px solid black`
                      : undefined,
                  marginRight: widthPercentage(6.5),
                  paddingBottom: widthPercentage(3),
                  cursor: "pointer",
                  fontWeight: index === tabIndex ? 500 : 400,
                  userSelect: "none",
                }}
                onClick={() => {
                  window.scrollTo({
                    left: 0,
                    top: tabScrollYOffset[tabIndex],
                    behavior: "smooth",
                  });
                }}
              >
                {tab.split(" ").slice(0, 2).join(" ")}
              </div>
            ))}
          </div>
          <div
            className="p-absolute"
            style={{
              right: 0,
              top: widthPercentage(2.5),
            }}
          >
            <div
              className="d-flex"
              style={{
                paddingLeft: widthPercentage(6),
                background:
                  "linear-gradient(270deg, #FFFFFF 75.69%, rgba(255, 255, 255, 0) 100%)",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  width: widthPercentage(8),
                  height: widthPercentage(8),
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.scrollTo({
                    left: 0,
                    top: guideContainerRef?.offsetTop ?? 0,
                    behavior: "smooth",
                  })
                }
              >
                <DoubleDownArrow
                  style={{
                    width: widthPercentage(5),
                    height: widthPercentage(5),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Hidden>
    </Fragment>
  );
}

interface HomeButtonProps {
  width?: number;
  children: ReactNode;
  handleClick?: () => void;
  disabled?: boolean;
  height?: number;
  noColor?: boolean;
  outlined?: boolean;
  selected?: boolean;
}

export function HomeButton({
  children,
  width,
  height,
  handleClick,
  disabled,
  noColor,
  outlined,
  selected,
}: HomeButtonProps) {
  return (
    <div
      className={
        "d-flex justify-content-center align-items-center home-button" +
        (disabled ? " disabled" : "") +
        (noColor ? " no-color" : "") +
        (outlined ? " outlined" : "") +
        (selected ? " selected" : "")
      }
      style={{
        width: width ?? "100%",
        height: height ?? widthPercentage(2.75),
        borderRadius: isMobile() ? widthPercentage(12.25) : widthPercentage(3),
        fontSize: isMobile() ? widthPercentage(4) : widthPercentage(0.9375),
      }}
      onClick={disabled ? undefined : handleClick}
    >
      {children}
    </div>
  );
}

function MobileProgramVisualComponent({
  program,
  index,
}: ProgramVisualComponentProps) {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const { signinWrapper } = useContext(GlobalContext);
  const { setTabScrollYOffset } = useContext(HomeContext);
  const [toggle, setToggle] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (containerRef) {
      const yOffset = containerRef.offsetTop;
      setTabScrollYOffset((tabScrollYOffset) => {
        tabScrollYOffset[index] = yOffset;
        return [...tabScrollYOffset];
      });
    }
  }, [containerRef, index, setTabScrollYOffset]);

  return (
    <div
      style={{ marginBottom: widthPercentage(33), userSelect: "none" }}
      ref={setContainerRef}
    >
      {program.program_status !== "progress" && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              fontSize: widthPercentage(3),
              border:
                program.program_status === "almost"
                  ? "1px solid #EA1A1A"
                  : "1px solid #111111",
              borderRadius: widthPercentage(21.5),
              color:
                program.program_status === "almost" ? "#EA1A1A" : textColor,
              display: "inline",
              padding: `${widthPercentage(0.5)}px ${widthPercentage(3)}px`,
            }}
          >
            {program.program_status === "almost" ? "마감임박" : "마감"}
          </div>
        </div>
      )}
      <div
        className="font-noto-medium"
        style={{
          marginTop: widthPercentage(2.5),
          fontSize: widthPercentage(6.75),
          textAlign: "center",
        }}
      >
        {program.program_name}
      </div>
      <div
        style={{
          marginTop: widthPercentage(3),
          fontSize: widthPercentage(4),
          textAlign: "center",
          color: "#505050",
        }}
      >
        {program.program_description}
      </div>
      <div
        style={{
          position: "relative",
          marginTop: widthPercentage(6.5),
          zIndex: -3,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
          }}
        >
          <div
            style={{
              width: widthPercentage(3),
              height: widthPercentage(174.5),
            }}
          >
            <MobileHalfLeftUpArrow
              style={{
                height: "100%",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: widthPercentage(23),
          height: widthPercentage(130),
          cursor: "pointer",
          position: "relative",
          zIndex: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            inset: 0,
            backgroundImage: `url(${program.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => history.push("/program/" + program.id)}
        ></div>
        <div
          className="p-absolute-center"
          style={{
            width: widthPercentage(72),
            height: widthPercentage(148),
            zIndex: -1,
            background: "#12033F",
            opacity: 0.12,
            filter: `blur(${widthPercentage(25)}px)`,
            borderRadius: widthPercentage(247.5),
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            zIndex: 2,
          }}
        >
          <div
            style={{
              width: widthPercentage(13.5),
              height: widthPercentage(13.5),
              position: "relative",
              cursor: "pointer",
              background: "rgba(0, 0, 0, 0.3)",
            }}
            onClick={() => setToggle(!toggle)}
          >
            <div className="p-absolute-center">
              {!toggle && (
                <AddIcon
                  style={{
                    width: widthPercentage(4),
                    height: widthPercentage(4),
                    color: "white",
                  }}
                />
              )}
              {toggle && (
                <CloseIcon
                  style={{
                    width: widthPercentage(4),
                    height: widthPercentage(4),
                    color: "white",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {toggle && (
          <div
            style={{
              position: "absolute",
              inset: 0,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: 1,
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                className="p-absolute-center"
                style={{
                  color: "white",
                  fontSize: widthPercentage(4),
                  width: "60%",
                }}
              >
                {program.program_progress_programs.map(
                  (program_progress_program, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : widthPercentage(4),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="font-roboto-regular"
                        style={{
                          marginRight: widthPercentage(2.5),
                          whiteSpace: "nowrap",
                        }}
                      >
                        {program.program_progress_programs.length === 1 &&
                          "원데이"}
                        {program.program_progress_programs.length > 1 &&
                          index + 1}
                      </div>
                      <div>{program_progress_program}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: widthPercentage(11),
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          zIndex: -2,
          position: "relative",
        }}
      >
        <div
          className="font-roboto-medium"
          style={{
            marginRight: widthPercentage(1.5),
            fontSize: widthPercentage(6.75),
          }}
        >
          {HomeProgramStartString(program.start_date)}
        </div>
        <div
          className="font-noto-medium"
          style={{
            fontSize: widthPercentage(5),
          }}
        >
          시작
        </div>
      </div>
      <div
        style={{
          marginTop: widthPercentage(15.5),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HomeButton
          width={widthPercentage(43)}
          height={widthPercentage(12)}
          handleClick={() => {
            if (program.registered)
              return window.open(program.pdf_file_location, "_blank");
            signinWrapper(() =>
              history.push({
                pathname: "/order",
                state: [program.id],
              })
            );
          }}
        >
          {program.registered ? "자료 확인하기" : "바로 신청하기"}
        </HomeButton>
      </div>
      <div
        style={{
          marginTop: widthPercentage(6.5),
        }}
      >
        <div
          style={{
            display: "flex",
            fontSize: widthPercentage(4),
            color: "#767676",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: widthPercentage(2.5) }}>
            #{program.location}
          </div>
          <div style={{ marginRight: widthPercentage(2.5) }}>
            #{program.rounds === 1 ? "원데이" : `${program.rounds}회차`}
          </div>
          <div>#{program.genres.join("+")}</div>
        </div>
      </div>
      <div
        style={{
          marginTop: widthPercentage(1),
          textAlign: "center",
          fontSize: widthPercentage(4),
          color: textColor,
        }}
      >
        {getProgramDateLabel(program)}
        {getWeekDay(new Date(program.start_date))}요일{" "}
        {formatProgramTime(
          program.starting_hour,
          program.starting_minute,
          program.minutes
        )}
      </div>
    </div>
  );
}

function getProgramDateLabel(program: HomeProgram) {
  const dates = program.dates;
  if (dates.length <= 1) {
    return "";
  }
  const date1 = moment(program.dates[0]);
  const date2 = moment(program.dates[1]);
  const dayDiff = Math.abs(date1.diff(date2, "days"));
  if (dayDiff === 7) {
    return "매주 ";
  }
  if (dayDiff === 14) {
    return "격주 ";
  }
  return "첫 모임 ";
}

function HomeProgramStartString(start_date: string) {
  const date = new Date(start_date);
  const month = (date.getMonth() + 1).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const day = date
    .getDate()
    .toLocaleString(undefined, { minimumIntegerDigits: 2 });
  return `${month}.${day}`;
}

interface ProgramVisualComponentProps {
  program: HomeProgram;
  index: number;
}

function ProgramVisualComponent({
  program,
  index,
}: ProgramVisualComponentProps) {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [leftRef, setLeftRef] = useState<HTMLDivElement | null>(null);
  const [rightRef, setRightRef] = useState<HTMLDivElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const [arrowWidth, setArrowWidth] = useState<number>(0);
  const [leftIncrease, setLeftIncrease] = useState<number>(0);
  const [rightDecrease, setRightDecrease] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const history = useHistory();
  const { signinWrapper } = useContext(GlobalContext);
  const { setTabScrollYOffset } = useContext(HomeContext);

  useEffect(() => {
    if (leftRef && rightRef) {
      setArrowWidth(
        rightRef.getBoundingClientRect().left -
          leftRef.getBoundingClientRect().left -
          leftRef.clientWidth -
          widthPercentage(5)
      );
    }
  }, [leftRef, rightRef]);

  useEffect(() => {
    // set amount to increase for left and right
    if (!loaded && arrowRef && leftRef && rightRef) {
      // get mid top coord
      const arrowMid =
        (arrowRef.getBoundingClientRect().top +
          arrowRef.getBoundingClientRect().bottom) /
        2;
      const leftMid =
        (leftRef.getBoundingClientRect().top +
          leftRef.getBoundingClientRect().bottom) /
        2;
      const rightMid =
        (rightRef.getBoundingClientRect().top +
          rightRef.getBoundingClientRect().bottom) /
        2;
      setLeftIncrease(Math.abs(arrowMid - leftMid));
      setRightDecrease(Math.abs(arrowMid - rightMid));
      setLoaded(true);
    }
  }, [arrowRef, leftRef, rightRef, loaded]);

  useEffect(() => {
    if (!loaded && containerRef) {
      const yOffset = containerRef.offsetTop;
      setTabScrollYOffset((tabScrollYOffset) => {
        tabScrollYOffset[index] = yOffset;
        return [...tabScrollYOffset];
      });
    }
  }, [containerRef, index, setTabScrollYOffset, loaded]);

  return (
    <div
      className="p-relative"
      style={{
        marginTop: widthPercentage(7.5),
        height: widthPercentage(38),
      }}
      ref={setContainerRef}
    >
      <div
        className="p-absolute"
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          width: arrowWidth,
          left: (leftRef?.clientWidth ?? 0) + widthPercentage(2.5),
          right:
            (rightRef?.getBoundingClientRect().left ?? 0) -
            widthPercentage(2.5),
        }}
        ref={setArrowRef}
      >
        <HalfLeftUpArrow style={{ width: "100%" }} />
      </div>
      <div
        style={{
          position: "absolute",
          width: widthPercentage(27.5),
          left: 0,
          top: `calc(50% - ${leftIncrease}px)`,
          transform: `translateY(calc(-(50% - ${leftIncrease}px)))`,
        }}
      >
        {program.program_status !== "progress" && (
          <div
            style={{
              fontSize: widthPercentage(0.75),
              border:
                program.program_status === "almost"
                  ? "1px solid #EA1A1A"
                  : "1px solid #111111",
              borderRadius: widthPercentage(4.8125),
              color:
                program.program_status === "almost" ? "#EA1A1A" : textColor,
              display: "inline",
              padding: `${widthPercentage(0.125)}px ${widthPercentage(0.75)}px`,
            }}
          >
            {program.program_status === "almost" ? "마감임박" : "마감"}
          </div>
        )}
        <div
          className="font-noto-medium"
          style={{
            marginTop: widthPercentage(0.5),
            width: widthPercentage(20),
            fontSize: widthPercentage(2.25),
            color: textColor,
            fontWeight: 500,
          }}
        >
          {program.program_name}
        </div>
        <div className="d-flex">
          <div
            className="d-flex font-noto-medium"
            style={{
              marginTop: widthPercentage(3.75),
              marginRight: widthPercentage(2.5),
              fontSize: widthPercentage(0.9375),
              color: textColor,
              fontWeight: 500,
            }}
            ref={setLeftRef}
          >
            {program.program_description}
          </div>
        </div>
        <div style={{ marginTop: widthPercentage(1.5) }}>
          {program.program_progress_programs.map(
            (program_progress_program, index) => (
              <div
                className="d-flex"
                style={{
                  marginBottom: widthPercentage(1),
                  fontSize: widthPercentage(0.9375),
                  color: "#767676",
                }}
                key={index}
              >
                <div
                  className="font-roboto-regular"
                  style={{ marginRight: widthPercentage(0.5) }}
                >
                  {program.program_progress_programs.length === 1 && "원데이"}
                  {program.program_progress_programs.length > 1 && index + 1}
                </div>
                <div>{program_progress_program}</div>
              </div>
            )
          )}
        </div>
      </div>
      <div
        className="p-absolute-center"
        style={{
          overflow: "hidden",
          width: widthPercentage(26.875),
          height: widthPercentage(38),
          cursor: "pointer",
        }}
      >
        <div
          className="home-hover-zoom"
          style={{
            backgroundImage: `url(${program.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => history.push("/program/" + program.id)}
        />
      </div>
      <div
        className="p-absolute-center"
        style={{
          width: widthPercentage(32.5),
          height: widthPercentage(32.5),
          background: "#12033F",
          opacity: 0.16,
          filter: `blur(${widthPercentage(6.25)}px)`,
          borderRadius: widthPercentage(61.875),
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: `calc(50% - ${rightDecrease}px)`,
          transform: `translateY(-(50% - ${rightDecrease}px))`,
          textAlign: "right",
        }}
      >
        <div
          className="d-flex justify-content-flex-end"
          style={{
            marginTop: widthPercentage(0.25),
            fontSize: widthPercentage(2),
            color: textColor,
          }}
        >
          <div
            className="font-roboto-medium"
            style={{
              marginRight: widthPercentage(0.5),
            }}
          >
            {HomeProgramStartString(program.start_date)}
          </div>
          <div className="font-noto-medium">시작</div>
        </div>
        <div
          className="d-flex justify-content-flex-end"
          style={{ marginTop: widthPercentage(2.5) }}
        >
          <div ref={setRightRef}>
            <HomeButton
              width={widthPercentage(9.5)}
              handleClick={() => {
                if (program.registered) {
                  return patchPdfDownloaded(program.id).then(() =>
                    window.open(program.pdf_file_location, "_blank")
                  );
                }
                signinWrapper(() =>
                  history.push({
                    pathname: "/order",
                    state: [program.id],
                  })
                );
              }}
            >
              {program.registered ? "자료 확인하기" : "바로 신청하기"}
            </HomeButton>
          </div>
        </div>
        <div
          style={{
            marginTop: widthPercentage(3.75),
            fontSize: widthPercentage(0.9375),
            color: textColor,
          }}
        >
          {getProgramDateLabel(program)}
          {getWeekDay(new Date(program.start_date))}요일{" "}
          {formatProgramTime(
            program.starting_hour,
            program.starting_minute,
            program.minutes
          )}
        </div>
        <div
          className="d-flex justify-content-flex-end"
          style={{
            marginTop: widthPercentage(0.5),
            fontSize: widthPercentage(0.9375),
            color: "#767676",
          }}
        >
          <div style={{ marginLeft: widthPercentage(0.5) }}>
            #{program.location}
          </div>
          <div style={{ marginLeft: widthPercentage(0.5) }}>
            #{program.rounds === 1 ? "원데이" : `${program.rounds}회차`}
          </div>
          <div style={{ marginLeft: widthPercentage(0.5) }}>
            #{program.genres.join("+")}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProgramListComponent() {
  const { homeData } = useContext(HomeContext);
  const history = useHistory();
  const refinedRecrutings = [
    ...homeData.recrutings,
  ] as (RecrutingProgram | null)[];
  if (refinedRecrutings.length === 0) return null;
  while (refinedRecrutings.length < 3) {
    refinedRecrutings.push(null);
  }
  return (
    <HomeContainer>
      <Hidden smDown>
        <div style={{ marginTop: widthPercentage(2.5) }}>
          <div className="d-flex align-items-flex-end">
            <div
              className="text-align-start font-noto-medium"
              style={{
                width: widthPercentage(16),
                fontSize: widthPercentage(2.25),
                fontWeight: 500,
              }}
            >
              지금 모집중인
              <br />
              프로그램을
              <br />
              만나보세요
            </div>
            <div style={{ flexGrow: 1 }} className="d-flex align-items-center">
              <div
                style={{ flexGrow: 1, background: "#4A148C", height: "1px" }}
              />
              <HomeButton
                width={widthPercentage(11.125)}
                handleClick={() => history.push("/program")}
              >
                프로그램 전체보기
              </HomeButton>
            </div>
          </div>
        </div>
        <div style={{ marginTop: widthPercentage(2.5) }}>
          <HomeSwiper
            slides={3}
            childWidth={widthPercentage(26.875)}
            buttonWidth={widthPercentage(2)}
            buttonHeight={widthPercentage(3)}
            offsetTop={widthPercentage(4.75)}
          >
            {refinedRecrutings.map((recruting, index) => (
              <div className="d-flex justify-content-center" key={index}>
                <ProgramCard
                  program={recruting}
                  index={index % 3}
                  width={widthPercentage(26.875)}
                  badge={recruting ? "NEW" : undefined}
                  cart={recruting?.cart}
                  blur={recruting === null}
                  blurText="COMING SOON"
                />
              </div>
            ))}
          </HomeSwiper>
        </div>
        <div
          style={{
            marginTop: widthPercentage(8),
            paddingTop: widthPercentage(0.5),
            position: "sticky",
            top: 0,
            zIndex: 10,
            backgroundColor: "white",
          }}
        >
          <TabComponent />
        </div>
        {homeData.programs.map((program, index) => (
          <ProgramVisualComponent program={program} key={index} index={index} />
        ))}
      </Hidden>
      <Hidden smUp>
        <div style={{ marginTop: widthPercentage(22) }}>
          <Grid container alignItems="flex-end">
            <Grid
              item
              className="text-align-start font-noto-medium"
              style={{
                width: widthPercentage(39),
                fontSize: widthPercentage(6.75),
                fontWeight: 500,
              }}
            >
              지금 모집중인
              <br />
              프로그램을
              <br />
              만나보세요
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center">
                <Grid item xs>
                  <div
                    style={{
                      height: widthPercentage(0.25),
                      background: "#4A148C",
                    }}
                  ></div>
                </Grid>
                <Grid item>
                  <HomeButton
                    width={widthPercentage(11)}
                    height={widthPercentage(9)}
                    handleClick={() => history.push("/program")}
                  >
                    <AddIcon
                      style={{
                        width: widthPercentage(4),
                        height: widthPercentage(4),
                      }}
                    />
                  </HomeButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: widthPercentage(11) }}>
          <HomeSwiper
            slides={1}
            childWidth={widthPercentage(92)}
            buttonWidth={widthPercentage(8)}
            buttonHeight={widthPercentage(12)}
            offsetTop={widthPercentage(15.375)}
          >
            {homeData.recrutings.map((recruting, index) => (
              <div className="d-flex justify-content-center" key={index}>
                <ProgramCard
                  program={recruting}
                  index={index % 3}
                  width={widthPercentage(92)}
                  badge={recruting ? "NEW" : undefined}
                  cart={recruting?.cart}
                />
              </div>
            ))}
          </HomeSwiper>
        </div>
        <div
          style={{
            marginTop: widthPercentage(15),
            paddingTop: widthPercentage(0.5),
            position: "sticky",
            top: 0,
            zIndex: 10,
            backgroundColor: "white",
            marginBottom: widthPercentage(11),
          }}
        >
          <TabComponent />
        </div>
        {homeData.programs.map((program, index) => (
          <MobileProgramVisualComponent
            program={program}
            key={index}
            index={index}
          />
        ))}
      </Hidden>
    </HomeContainer>
  );
}

function GuideComponent() {
  const [total, setTotal] = useState<number>(0);
  const [current, setCurrent] = useState<number>(1);
  const [swiperRef, setSwiperRef] = useState<HTMLDivElement | null>(null);
  const { setGuideContainerRef, homeData } = useContext(HomeContext);
  const [toggle, setToggle] = useState<boolean>(false);

  useEffect(() => {
    setTotal(homeData.youtubes.length);
  }, [homeData]);

  const next = () => {
    if (current === total) return;
    if (swiperRef) {
      (swiperRef as any).swiper.slideNext();
    }
  };

  const prev = () => {
    if (current === 1) return;
    if (swiperRef) {
      (swiperRef as any).swiper.slidePrev();
    }
  };

  useEffect(() => {
    if (swiperRef) {
      const swiper = (swiperRef as any).swiper;
      swiper.on("slideChange", ({ realIndex }: { realIndex: number }) =>
        setCurrent(realIndex + 1)
      );
    }
  }, [swiperRef]);

  const GuideCard = ({ guide }: { guide: HomeGuide }) => {
    const { signinWrapper } = useContext(GlobalContext);
    return (
      <Fragment>
        <Hidden smDown>
          <div
            style={{
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() =>
              signinWrapper(() => window.open(guide.link, "_blank"))
            }
          >
            <div
              style={{
                width: widthPercentage(20),
                height: widthPercentage(20),
                backgroundImage: `url(${guide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div
              style={{
                marginTop: widthPercentage(1),
                height: widthPercentage(2.875),
                width: widthPercentage(19),
                fontSize: widthPercentage(1),
                color: "white",
              }}
            >
              {guide.content}
            </div>
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            style={{
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() =>
              signinWrapper(() => window.open(guide.link, "_blank"))
            }
          >
            <div
              style={{
                width: widthPercentage(66.5),
                height: widthPercentage(66.5),
                backgroundImage: `url(${guide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div
              style={{
                marginTop: widthPercentage(3.5),
                fontSize: widthPercentage(5),
                color: "white",
              }}
            >
              {guide.content}
            </div>
          </div>
        </Hidden>
      </Fragment>
    );
  };

  const videoWidth = widthPercentage(61.25);
  const videoHeight = widthPercentage(34.375);

  return (
    <Fragment>
      <Hidden smDown>
        <div
          style={{
            marginTop: widthPercentage(11.25),
            background: "#181414",
            paddingBottom: widthPercentage(11.25),
          }}
          ref={setGuideContainerRef}
        >
          <HomeContainer>
            <div
              className="font-noto-medium"
              style={{
                paddingTop: widthPercentage(7.5),
                color: "white",
                fontSize: widthPercentage(2.25),
                fontWeight: 500,
              }}
            >
              초심자를 위한 가이드
            </div>
            <div
              style={{
                marginTop: widthPercentage(0.75),
                color: "#AAAAAA",
                fontSize: widthPercentage(0.9375),
              }}
            >
              필로어스에 처음 오시는 분들을 위한 필수 가이드
            </div>
            <div
              style={{
                marginTop: widthPercentage(5),
                width: widthPercentage(40.625),
              }}
            >
              <HomeSwiper
                slides={2}
                childWidth={widthPercentage(20)}
                buttonWidth={widthPercentage(2)}
                buttonHeight={widthPercentage(3)}
                offsetTop={widthPercentage(8.5)}
              >
                {homeData.guides.map((guide, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <GuideCard guide={guide} />
                  </div>
                ))}
              </HomeSwiper>
            </div>
            <div
              className="d-flex justify-content-flex-end"
              style={{
                marginTop: widthPercentage(11.25),
              }}
            >
              <div
                style={{
                  width: videoWidth,
                }}
              >
                <Swiper
                  spaceBetween={widthPercentage(0.625)}
                  noSwiping
                  ref={setSwiperRef}
                  slidesPerView={1}
                >
                  {homeData.youtubes.map((youtube, index) => (
                    <div
                      style={{
                        width: videoWidth,
                        height: videoHeight,
                      }}
                      key={index}
                    >
                      <iframe
                        src={
                          "https://www.youtube.com/embed/" + youtube.embed_id
                        }
                        frameBorder="0"
                        allowFullScreen
                        title="video"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  ))}
                </Swiper>
                {homeData.youtubes.length > 0 && (
                  <div className="d-flex justify-content-space-between align-items-flex-end">
                    <div>
                      <div
                        style={{
                          marginTop: widthPercentage(1),
                          fontSize: widthPercentage(0.9375),
                          color: "#AAAAAA",
                        }}
                      >
                        {homeData.youtubes[current - 1].title1}
                      </div>
                      <div
                        style={{
                          marginTop: widthPercentage(0.125),
                          fontSize: widthPercentage(1.125),
                          color: "white",
                        }}
                      >
                        {homeData.youtubes[current - 1].title2}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-flex-end"
                      style={{ userSelect: "none" }}
                    >
                      <div
                        style={{
                          marginBottom: widthPercentage(0.375),
                          width: widthPercentage(2),
                          height: widthPercentage(2),
                          cursor: current === 1 ? "inherit" : "pointer",
                        }}
                        onClick={prev}
                      >
                        <LeftArrow
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: current === 1 ? 0.35 : 1,
                          }}
                        />
                      </div>
                      <div
                        className="font-roboto-regular"
                        style={{
                          marginLeft: widthPercentage(2.5),
                          fontSize: widthPercentage(2.25),
                          color: "white",
                        }}
                      >
                        {current}
                      </div>
                      <div
                        className="font-roboto-regular"
                        style={{
                          marginBottom: widthPercentage(0.3125),
                          fontSize: widthPercentage(1.125),
                          color: "white",
                        }}
                      >
                        /{total}
                      </div>
                      <div
                        style={{
                          marginLeft: widthPercentage(2.5),
                          marginBottom: widthPercentage(0.375),
                          width: widthPercentage(2),
                          height: widthPercentage(2),
                          cursor: current === total ? "inherit" : "pointer",
                        }}
                        onClick={next}
                      >
                        <RightArrow
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: current === total ? 0.35 : 1,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </HomeContainer>
        </div>
      </Hidden>
      <Hidden smUp>
        <div
          style={{
            paddingTop: widthPercentage(22),
            background: "#181414",
            userSelect: "none",
          }}
          ref={setGuideContainerRef}
        >
          <HomeContainer>
            <div
              className="font-noto-medium"
              style={{
                color: "white",
                fontSize: widthPercentage(6.75),
                fontWeight: 500,
              }}
            >
              초심자를 위한 가이드
            </div>
            <div
              style={{
                marginTop: widthPercentage(2.5),
                color: "#AAAAAA",
                fontSize: widthPercentage(4),
              }}
            >
              필로어스에 처음 오시는 분들을 위한 필수 가이드
            </div>
            <div
              style={{
                marginTop: widthPercentage(22),
              }}
            >
              {homeData.guides
                .slice(0, toggle ? undefined : 2)
                .map((guide, index) => (
                  <div
                    className="d-flex justify-content-center"
                    key={index}
                    style={{
                      marginBottom: widthPercentage(11),
                    }}
                  >
                    <GuideCard guide={guide} />
                  </div>
                ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: widthPercentage(50),
                  height: widthPercentage(12),
                  borderRadius: widthPercentage(12.25),
                  border: `${widthPercentage(0.25)}px solid white`,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setToggle(!toggle)}
              >
                <div>
                  {!toggle && "전체 펼치기"}
                  {toggle && "접기"}
                </div>
                <div
                  style={{
                    width: widthPercentage(6),
                    height: widthPercentage(6),
                    marginLeft: widthPercentage(1),
                  }}
                >
                  {!toggle && (
                    <KeyboardArrowDownIcon
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                  {toggle && (
                    <KeyboardArrowUpIcon
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </HomeContainer>
          <div
            style={{
              marginTop: widthPercentage(33),
              paddingBottom: widthPercentage(33),
            }}
          >
            <Swiper ref={setSwiperRef} slidesPerView={1}>
              {homeData.youtubes.map((youtube, index) => (
                <div key={index}>
                  <div
                    style={{
                      height: widthPercentage(56),
                    }}
                  >
                    <iframe
                      src={"https://www.youtube.com/embed/" + youtube.embed_id}
                      frameBorder="0"
                      allowFullScreen
                      title="video"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <HomeContainer>
                    <div
                      style={{
                        marginTop: widthPercentage(3.5),
                        fontSize: widthPercentage(4),
                        color: "#AAAAAA",
                      }}
                    >
                      {youtube.title1}
                    </div>
                    <div
                      style={{
                        marginTop: widthPercentage(1),
                        fontSize: widthPercentage(5),
                        color: "white",
                      }}
                    >
                      {youtube.title2}
                    </div>
                    <div
                      style={{
                        marginTop: widthPercentage(9),
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SwiperPagination
                        dark
                        currentIndex={current}
                        amount={homeData.youtubes.length}
                        ithButtonClicked={(i) =>
                          (swiperRef as any).swiper.slideTo(i)
                        }
                      />
                    </div>
                  </HomeContainer>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </Hidden>
    </Fragment>
  );
}

function TutorComponent() {
  const { homeData } = useContext(HomeContext);
  const [index, setIndex] = useState<number>(0);
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const onLeftClick = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const onRightClick = () => {
    if (index < homeData.tutors.length - 1) {
      setIndex(index + 1);
    }
  };

  if (homeData.tutors.length === 0) return null;
  const tutor = homeData.tutors[index];

  const openTutorInterview = () => {
    parsed.tutor_detail_id = tutor.tutor_id.toString();
    location.search = queryString.stringify(parsed);
    history.replace({ search: location.search });
  };

  return (
    <Fragment>
      <Hidden smDown>
        <div className="p-relative">
          <div
            className="p-absolute"
            style={{
              zIndex: -3,
              backgroundColor: "#F1F1FA",
              top: 0,
              left: 0,
              right: 0,
              height: widthPercentage(32.375),
            }}
          ></div>
          <HomeContainer>
            <div
              className="font-noto-medium"
              style={{
                paddingTop: widthPercentage(7.5),
                fontSize: widthPercentage(2.25),
                width: widthPercentage(20),
                fontWeight: 500,
              }}
            >
              매달 업데이트 되는
              <br />
              튜터들의 생각을
              <br />
              만나보세요
            </div>
            <div
              className="d-flex justify-content-space-between"
              style={{
                marginTop: widthPercentage(2.5),
              }}
            >
              <div>
                <div
                  style={{
                    color: "#767676",
                    fontSize: widthPercentage(0.9375),
                  }}
                >
                  이전의 인터뷰는 튜터 프로필에 업데이트 될 예정입니다
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(1),
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      backgroundColor: "white",
                      height: widthPercentage(2.75),
                      fontSize: widthPercentage(0.875),
                      color: textColor,
                      padding: `${widthPercentage(0.75)}px ${widthPercentage(
                        2
                      )}px`,
                      borderRadius: widthPercentage(3.0625),
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    onClick={() => history.push("/tutor/profile")}
                  >
                    튜터 프로필 이동
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: widthPercentage(7.5),
                  }}
                >
                  <div className="p-relative">
                    <div
                      className="p-absolute"
                      style={{
                        zIndex: -1,
                        left: widthPercentage(0.5),
                        width: widthPercentage(40),
                        height: widthPercentage(9),
                        top: widthPercentage(-1.25),
                      }}
                    >
                      <InterviewLogo
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(5),
                    width: widthPercentage(40),
                    fontSize: widthPercentage(2),
                    color: textColor,
                    whiteSpace: "pre-line",
                  }}
                >
                  {tutor.title}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(1),
                    fontSize: widthPercentage(0.9375),
                    color: "#4A148C",
                  }}
                >
                  튜터 {tutor.tutor_name}
                </div>
                <div
                  style={{
                    marginTop: widthPercentage(2),
                    width: widthPercentage(32),
                    fontSize: widthPercentage(0.9375),
                    color: "#767676",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {tutor.description}
                </div>
              </div>
              <div
                style={{
                  width: widthPercentage(26.875),
                  height: widthPercentage(40),
                  overflow: "hidden",
                }}
              >
                <div
                  className="p-relative"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div
                    className="home-hover-zoom"
                    style={{
                      backgroundImage: `url(${tutor.image1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                    }}
                    onClick={openTutorInterview}
                  ></div>
                  <div
                    className="p-absolute"
                    style={{
                      bottom: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  >
                    <CustomLeftRightButton
                      onLeftClick={onLeftClick}
                      onRightClick={onRightClick}
                      isLeftEnd={index === 0}
                      isRightEnd={index === homeData.tutors.length - 1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </HomeContainer>
        </div>
      </Hidden>
      <Hidden smUp>
        <div
          style={{
            paddingTop: widthPercentage(22),
            backgroundColor: "#F1F1FA",
          }}
        >
          <HomeContainer>
            <div
              className="font-noto-medium"
              style={{
                fontSize: widthPercentage(6.75),
              }}
            >
              매달 업데이트 되는
              <br />
              튜터들의 생각을
              <br />
              만나보세요
            </div>
            <div
              style={{
                marginTop: widthPercentage(6.5),
                color: "#767676",
                fontSize: widthPercentage(3.5),
              }}
            >
              이전의 인터뷰는 튜터 프로필에 업데이트 될 예정입니다
            </div>
            <div
              style={{
                marginTop: widthPercentage(4),
              }}
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "white",
                  width: widthPercentage(48.75),
                  height: widthPercentage(12),
                  fontSize: widthPercentage(4),
                  color: textColor,
                  borderRadius: widthPercentage(12.25),
                  cursor: "pointer",
                  userSelect: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => history.push("/tutor/profile")}
              >
                튜터 프로필 이동
              </div>
            </div>
          </HomeContainer>
        </div>
        <div
          style={{
            paddingTop: widthPercentage(11),
            backgroundColor: "#F1F1FA",
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: widthPercentage(60.25),
                backgroundColor: "#F1F1FA",
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            marginLeft: widthPercentage(4),
            height: widthPercentage(142.75),
          }}
        >
          <div
            onClick={openTutorInterview}
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${tutor.image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor: "pointer",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: widthPercentage(11),
            }}
          >
            <CustomLeftRightButton
              onLeftClick={onLeftClick}
              onRightClick={onRightClick}
              isLeftEnd={index === 0}
              isRightEnd={index === homeData.tutors.length - 1}
            />
          </div>
        </div>
        <HomeContainer>
          <div
            style={{
              position: "relative",
              marginTop: -widthPercentage(11),
              paddingTop: widthPercentage(5.5),
              paddingRight: widthPercentage(4),
              backgroundColor: "white",
              paddingBottom: widthPercentage(22),
            }}
          >
            <div
              style={{
                fontSize: widthPercentage(5),
                color: textColor,
                whiteSpace: "pre-line",
              }}
            >
              {tutor.title}
            </div>
            <div
              style={{
                marginTop: widthPercentage(2.5),
                fontSize: widthPercentage(4),
                color: "#4A148C",
              }}
            >
              튜터 {tutor.tutor_name}
            </div>
            <div
              style={{
                marginTop: widthPercentage(5.5),
                fontSize: widthPercentage(4),
                color: "#767676",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {tutor.description}
            </div>
          </div>
        </HomeContainer>
      </Hidden>
    </Fragment>
  );
}

interface CustomLeftRightButtonProps {
  onLeftClick: () => void;
  onRightClick: () => void;
  isLeftEnd: boolean;
  isRightEnd: boolean;
}

export function CustomLeftRightButton({
  onLeftClick,
  onRightClick,
  isLeftEnd,
  isRightEnd,
}: CustomLeftRightButtonProps) {
  return (
    <div className="d-flex">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          background: "rgba(0, 0, 0, 0.3)",
          cursor: !isLeftEnd ? "pointer" : "inherit",
          opacity: !isLeftEnd ? 1 : 0.35,
          width: isMobile() ? widthPercentage(13.5) : widthPercentage(3.25),
          height: isMobile() ? widthPercentage(13.5) : widthPercentage(3.25),
        }}
        onClick={!isLeftEnd ? onLeftClick : undefined}
      >
        <LeftArrow
          style={{
            width: isMobile() ? widthPercentage(4) : widthPercentage(1.125),
            height: isMobile() ? widthPercentage(4) : widthPercentage(1.125),
          }}
        />
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          background: "rgba(0, 0, 0, 0.3)",
          cursor: !isRightEnd ? "pointer" : "inherit",
          opacity: !isRightEnd ? 1 : 0.35,
          width: isMobile() ? widthPercentage(13.5) : widthPercentage(3.25),
          height: isMobile() ? widthPercentage(13.5) : widthPercentage(3.25),
        }}
        onClick={!isRightEnd ? onRightClick : undefined}
      >
        <RightArrow
          style={{
            width: isMobile() ? widthPercentage(4) : widthPercentage(1.125),
            height: isMobile() ? widthPercentage(4) : widthPercentage(1.125),
          }}
        />
      </div>
    </div>
  );
}

interface TutorInterview {
  question: string;
  answer: string;
}

export interface TutorInterviewInfo {
  interview_date: string;
  interviews: TutorInterview[];
  image: string;
  image1: string;
  image2: string;
}

interface HomeDialogProps {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
}

export function HomeDialog({ open, handleClose, children }: HomeDialogProps) {
  const [childrenRef, setChildrenRef] = useState<HTMLDivElement | null>(null);
  const [closeTop, setCloseTop] = useState<number>(0);
  const [closeRight, setCloseRight] = useState<number>(0);

  useEffect(() => {
    if (childrenRef) {
      if (isMobile()) {
        setCloseTop(widthPercentage(19));
        setCloseRight(widthPercentage(4));
      } else {
        setCloseTop(
          childrenRef.getBoundingClientRect().top + widthPercentage(1.5)
        );
        setCloseRight(
          childrenRef.getBoundingClientRect().right -
            widthPercentage(3.25) -
            widthPercentage(1.5)
        );
      }
    }
  }, [childrenRef]);

  return (
    <Fragment>
      <Dialog
        {...(isMobile()
          ? {
              style: {
                top: widthPercentage(15),
              },
              fullScreen: true,
              PaperProps: {
                sx: {
                  borderRadius: `${widthPercentage(4)}px ${widthPercentage(
                    4
                  )}px 0 0`,
                  zIndex: 10,
                },
              },
            }
          : {
              PaperProps: {
                sx: {
                  borderRadius: "30px",
                },
              },
            })}
        open={open}
        onClose={handleClose}
        maxWidth={false}
      >
        {childrenRef && (
          <div
            style={{
              position: "fixed",
              zIndex: 3,
              top: closeTop,
              left: isMobile() ? undefined : closeRight,
              right: isMobile() ? closeRight : undefined,
            }}
          >
            <IconButton
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderRadius: "50%",
                width: widthPercentage(isMobile() ? 13.5 : 3.25),
                height: widthPercentage(isMobile() ? 13.5 : 3.25),
                padding: widthPercentage(isMobile() ? 2.5 : 0.625),
              }}
              onClick={handleClose}
            >
              <CloseIcon
                style={{ color: "white", width: "100%", height: "100%" }}
              />
            </IconButton>
          </div>
        )}
        <div ref={setChildrenRef} style={{ height: "100%" }}>
          {children}
        </div>
      </Dialog>
    </Fragment>
  );
}

function ProgramReviewComponent() {
  const { homeData } = useContext(HomeContext);
  const history = useHistory();

  return (
    <Fragment>
      <Hidden smDown>
        <HomeContainer>
          <div
            style={{
              paddingTop: widthPercentage(7.5),
              paddingBottom: widthPercentage(8.9375),
            }}
          >
            <div className="d-flex align-items-flex-end">
              <div
                className="font-noto-medium"
                style={{
                  fontSize: widthPercentage(2.25),
                  width: widthPercentage(16),
                }}
              >
                프로그램에
                <br />
                참여한 소감을
                <br />
                들어보세요
              </div>
              <div
                style={{ flexGrow: 1 }}
                className="d-flex align-items-center"
              >
                <div
                  style={{ flexGrow: 1, background: "#4A148C", height: "1px" }}
                />
                <HomeButton
                  width={widthPercentage(13.125)}
                  handleClick={() => history.push("/community")}
                >
                  프로그램 후기 전체보기
                </HomeButton>
              </div>
            </div>
            <div style={{ marginTop: widthPercentage(2.5) }}>
              <HomeSwiper
                slides={4}
                buttonWidth={widthPercentage(2)}
                buttonHeight={widthPercentage(3)}
                childWidth={widthPercentage(20)}
                offsetTop={widthPercentage(4.75)}
              >
                {homeData.reviews.map((review, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <ProgramCard
                      program={{
                        id: review.program_id,
                        image: review.image,
                        program_name: review.program_name,
                        program_description: review.program_description,
                      }}
                      index={index % 4}
                      width={widthPercentage(20)}
                      review={review}
                    />
                  </div>
                ))}
              </HomeSwiper>
            </div>
          </div>
        </HomeContainer>
      </Hidden>
      <Hidden smUp>
        <HomeContainer>
          <div
            style={{
              paddingBottom: widthPercentage(22),
            }}
          >
            <div className="d-flex align-items-flex-end">
              <div
                className="font-noto-medium"
                style={{
                  fontSize: widthPercentage(6.75),
                  width: widthPercentage(39),
                }}
              >
                프로그램에
                <br />
                참여한 소감을
                <br />
                들어보세요
              </div>
              <div
                style={{ flexGrow: 1 }}
                className="d-flex align-items-center"
              >
                <div
                  style={{
                    flexGrow: 1,
                    background: "#4A148C",
                    height: widthPercentage(0.25),
                  }}
                />
                <HomeButton
                  width={widthPercentage(11)}
                  height={widthPercentage(9)}
                  handleClick={() => history.push("/community")}
                >
                  <AddIcon
                    style={{
                      width: widthPercentage(4),
                      height: widthPercentage(4),
                    }}
                  />
                </HomeButton>
              </div>
            </div>
            <div style={{ marginTop: widthPercentage(11) }}>
              <HomeSwiper
                slides={1}
                childWidth={widthPercentage(92)}
                buttonWidth={widthPercentage(8)}
                buttonHeight={widthPercentage(12)}
                offsetTop={widthPercentage(15.375)}
              >
                {homeData.reviews.map((review, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <ProgramCard
                      program={{
                        id: review.program_id,
                        image: review.image,
                        program_name: review.program_name,
                        program_description: review.program_description,
                      }}
                      index={index % 4}
                      width={widthPercentage(92)}
                      review={review}
                    />
                  </div>
                ))}
              </HomeSwiper>
            </div>
          </div>
        </HomeContainer>
      </Hidden>
    </Fragment>
  );
}

interface HomeContextProps {
  guideContainerRef: HTMLDivElement | null;
  setGuideContainerRef: (elem: HTMLDivElement) => void;
  homeData: HomeData;
  tabScrollYOffset: number[];
  setTabScrollYOffset: React.Dispatch<React.SetStateAction<number[]>>;
  currentYOffset: number;
  refresh: () => void;
}

const HomeContext = React.createContext({} as HomeContextProps);

interface RecrutingProgram {
  id: number;
  image: string;
  program_name: string;
  program_description: string;
  cart?: boolean;
}

interface HomeProgram {
  id: number;
  image: string;
  location: string;
  program_description: string;
  program_name: string;
  program_status: ProgramStatus;
  rounds: number;
  start_date: string;
  genres: string[];
  starting_hour: number;
  starting_minute: number;
  minutes: number;
  program_progress_programs: string[];
  registered: boolean;
  dates: string[];
  pdf_file_location: string;
}

interface HomeGuide {
  id: string;
  image: string;
  content: string;
  link: string;
}

interface HomeYoutube {
  id: string;
  title1: string;
  title2: string;
  embed_id: string;
}

interface HomeTutor {
  id: string;
  tutor_id: number;
  tutor_name: string;
  title: string;
  description: string;
  image1: string;
}

interface HomeBanner {
  image: string;
  mobile_image: string;
  link: string;
}

export interface HomeReview {
  program_id: number;
  image: string;
  program_header: string;
  program_description: string;
  program_name: string;
  tutor_names: string[];
  tutor_ids: number[];
  tutor_statuses: TutorStatus[];
  program_review_id: number;
  program_review_date: string;
  program_review_detail: string;
  program_reviewed_user_name: string;
  votes: number;
  voted: boolean;
}

interface HomeData {
  recrutings: RecrutingProgram[];
  programs: HomeProgram[];
  banners: HomeBanner[];
  guides: HomeGuide[];
  youtubes: HomeYoutube[];
  tutors: HomeTutor[];
  reviews: HomeReview[];
}

function Home() {
  const [guideContainerRef, setGuideContainerRef] =
    useState<HTMLDivElement | null>(null);

  const [tabScrollYOffset, setTabScrollYOffset] = useState<number[]>([]);

  const [homeData, setHomeData] = useState<HomeData | null>(null);
  const [render, setRender] = useState<number>(0);
  const [currentYOffset, setCurrentYOffset] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    fetchDefaultWithCredential(`/home`, "GET").then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(setHomeData);
    });
  }, [render]);

  useEffect(() => {
    if (!loaded && homeData) {
      setTabScrollYOffset(Array(homeData.programs.length).fill(0));
      setLoaded(true);
    }
  }, [homeData, loaded]);

  useEffect(() => {
    const onScroll = () => setCurrentYOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  if (!loaded || !homeData) return null;

  return (
    <HomeContext.Provider
      value={{
        guideContainerRef,
        setGuideContainerRef,
        homeData,
        tabScrollYOffset,
        setTabScrollYOffset,
        currentYOffset,
        refresh: () => setRender(render + 1),
      }}
    >
      <div className="margin-center font-noto-regular">
        <BannerComponent />
        <InfoInitComponent />
        <MainLetterComponent />
        <ProgramListComponent />
        <GuideComponent />
        <TutorComponent />
        <ProgramReviewComponent />
      </div>
    </HomeContext.Provider>
  );
}

export default Home;
