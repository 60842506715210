import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  Grid,
} from "@mui/material";
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchDefaultWithCredential,
  formatDashedMonthDate,
  formatProgramDate,
  formatProgramDetailDates,
  formatProgramTime,
  isMobile,
} from "../utils";
import { Template } from "./admins/pages/edits/programs/Templates";
import CustomButton from "./customs/CustomButton";
import {
  ProgramProps,
  ProgramComponent,
  ProgramStatus,
  ProgramStatusComponent,
  LocationComponent,
} from "./Program";
import { AdminUserReview } from "./admins/pages/users/Review";
import CustomDialog from "./customs/CustomDialog";
import { useUser } from "../globals/user";
import {
  GlobalContext,
  SnackbarContext,
} from "../globals/components/ComponentsWrapper";
import { CustomAvatarWithLocation } from "./customs/CustomAvatar";
import ProgramDetailMemberPng from "../pngs/program_detail_member.png";
import { ReactComponent as CouponSvg } from "../svg/coupon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomSwiper from "./customs/CustomSwiper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface SummaryProgramCardProps extends LabelingButtonProps {
  fullWidth?: boolean;
}

function SummaryProgramCard({
  programDetail,
  patchProgramWait,
  patchProgramCart,
  WaitDialog,
  handleRegister,
  fullWidth,
  noCart,
}: SummaryProgramCardProps) {
  return (
    <div
      style={{
        width: fullWidth ? "100%" : isMobile() ? 180 : 270,
        borderRadius: isMobile() ? 5 : 8,
        border: "1px solid #D9D9D9",
        cursor: "pointer",
        boxShadow: "5px 3px 9px 0px #0000003D",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "relative",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <img
          src={programDetail.program_image}
          draggable={false}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            height: 130,
            background:
              "linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #464646 57.78%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div
            style={{
              padding: 15,
            }}
          >
            <div style={{ display: "flex", columnGap: 5 }}>
              <ProgramStatusComponent status={programDetail.program_status} />
              <LocationComponent location={programDetail.location} />
            </div>
            <div
              style={{
                marginTop: 5,
                fontSize: isMobile() ? 15 : 10,
                fontWeight: 400,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                color: "white",
              }}
            >
              {(() => {
                let dates = programDetail.dates;
                // check if we should add more onto dates or not
                if (programDetail.custom_date) {
                  dates = [
                    ...dates,
                    programDetail.custom_date_start,
                    programDetail.custom_date_end,
                  ];
                }
                return formatProgramDate(dates);
              })()}{" "}
              {formatProgramTime(
                programDetail.starting_hour,
                programDetail.starting_minute,
                programDetail.minutes
              )}
            </div>
            <div
              style={{
                fontSize: isMobile() ? 15 : 10,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                color: "white",
                fontWeight: 700,
              }}
            >
              {(() => {
                const dates = programDetail.dates;
                const price = programDetail.price;
                const distinctMonths = new Set(
                  dates.map((date) => new Date(date).getMonth())
                );
                let label = "";
                if (dates.length === 1) {
                  label = "원데이";
                } else if (
                  programDetail.combined_program_type_name === "글쓰기 챌린지"
                ) {
                  label = "위대한 질문 글쓰기 챌린지";
                } else {
                  const dividedPrice = Math.floor(price / dates.length);
                  label = `[${distinctMonths.size}개월${
                    distinctMonths.size > 1 ? " 무이자" : ""
                  }] ${dividedPrice.toLocaleString()}원 x ${dates.length}회`;
                }
                return (
                  <Fragment>
                    {label} = {price.toLocaleString()}원
                  </Fragment>
                );
              })()}
            </div>
          </div>
        </div>
      </div>
      <LabelingButton
        programDetail={programDetail}
        patchProgramWait={patchProgramWait}
        patchProgramCart={patchProgramCart}
        WaitDialog={WaitDialog}
        handleRegister={handleRegister}
        noCart={noCart}
      />
    </div>
  );
}

interface ProgramProgressComponentProps {
  seminarNames: string[];
  seminarDates: string[];
  progressDescriptions: string[];
}

function ProgramProgressComponent({
  seminarNames,
  seminarDates,
  progressDescriptions,
}: ProgramProgressComponentProps) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 30,
          overflow: isMobile() ? "auto" : undefined,
        }}
      >
        {(() => {
          const nums = isMobile() ? seminarNames.length : 3;
          const res = [];
          for (let i = 0; i < seminarNames.length; i += nums) {
            res.push(
              <div
                style={{
                  display: "flex",
                  columnGap: 30,
                }}
                key={i}
              >
                {(() => {
                  const res = [];
                  for (let j = 0; j < nums; j++) {
                    if (!seminarNames[i + j]) {
                      res.push(
                        <div
                          key={j}
                          style={{
                            flex: isMobile() ? "1 0 50%" : 1,
                            alignSelf: "stretch",
                          }}
                        />
                      );
                      continue;
                    }
                    res.push(
                      <div
                        key={j}
                        style={{
                          flex: isMobile() ? "1 0 50%" : 1,
                          alignSelf: "stretch",
                          position: "relative",
                        }}
                      >
                        {i + j < seminarNames.length - 1 && (
                          <div
                            style={{
                              position: "absolute",
                              left: "100%",
                              top: "50%",
                              transform: "translate(-25%, -50%)",
                              zIndex: 1,
                            }}
                          >
                            <div
                              style={{
                                width: 55,
                                height: 55,
                                borderRadius: "50%",
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderLeft: "10px solid #D9D9D9",
                                  borderTop: "7.5px solid transparent",
                                  borderBottom: "7.5px solid transparent",
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                        <div
                          style={{
                            backgroundColor: "#F1F1FA",
                            borderRadius: 20,
                            padding: 30,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            height: 250,
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              borderRadius: 12,
                              backgroundColor: "white",
                              fontSize: 14,
                              fontWeight: 700,
                              textAlign: "center",
                              padding: "5px 10px",
                              top: 30,
                              left: 30,
                              right: 30,
                            }}
                          >
                            {i + j + 1}회차
                          </div>
                          <div
                            style={{
                              color: "#4A148C",
                              fontSize: 18,
                              fontWeight: 700,
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "80%",
                              textAlign: "center",
                              wordBreak: "keep-all",
                            }}
                          >
                            {seminarNames[i + j]}
                          </div>
                          {seminarDates[i + j] && (
                            <div
                              style={{
                                fontSize: 16,
                                fontWeight: 500,
                                position: "absolute",
                                bottom: 30,
                                left: 30,
                                right: 30,
                                textAlign: "center",
                              }}
                            >
                              {formatProgramDetailDates([seminarDates[i + j]])}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                  return res;
                })()}
              </div>
            );
          }
          return res;
        })()}
      </div>
      <div style={{ marginTop: 30 }}>
        {progressDescriptions.map((progressDescription, index) => (
          <div key={index} style={{ fontSize: 14, color: "#767676" }}>
            * {progressDescription}
          </div>
        ))}
      </div>
    </>
  );
}

interface ClickIndexComponentProps {
  labels: ReactNode[];
  height: number;
  images?: ReactNode[];
  fullImages?: ReactNode[];
  descriptions?: ReactNode[];
}

function ClickIndexComponent({
  labels,
  height,
  images,
  descriptions,
  fullImages,
}: ClickIndexComponentProps) {
  const [curr, setCurr] = useState<number>(0);

  return (
    <>
      <div
        style={{
          position: "relative",
          borderTopLeftRadius: 50,
          borderTopRightRadius: 50,
          backgroundColor: "#F1F1FA",
          height,
          borderBottom: "1px solid #4A148C",
        }}
      >
        {fullImages && Boolean(fullImages[curr]) && (
          <div
            style={{
              left: 0,
              right: 0,
              position: "absolute",
              bottom: isMobile() ? 70 : 100,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                boxShadow: "2px 13px 19.5px 0px #00000038",
              }}
            >
              {fullImages[curr]}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          {labels.map((label, index) => (
            <div
              key={index}
              style={{
                cursor: "pointer",
              }}
              onClick={() => setCurr(index)}
            >
              {images && (
                <div
                  style={{
                    position: "relative",
                    boxShadow: "2px 13px 19.5px 0px #00000038",
                  }}
                >
                  {curr !== index && (
                    <div
                      style={{
                        position: "absolute",
                        inset: 0,
                        backgroundColor: "white",
                        opacity: 0.7,
                      }}
                    ></div>
                  )}
                  {images[index]}
                </div>
              )}
              <div
                style={{
                  marginTop: isMobile() ? 15 : 30,
                  color: curr === index ? "#4A148C" : "#999999",
                  textAlign: "center",
                }}
              >
                {label}
              </div>
              {index === curr && (
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 30,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      borderLeft: "9.5px solid transparent",
                      borderRight: "9.5px solid transparent",
                      borderBottom: "11px solid #4A148C",
                    }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {descriptions && Boolean(descriptions[curr]) && (
        <div style={{ marginTop: 30 }}>{descriptions[curr]}</div>
      )}
    </>
  );
}

interface ProgramEvent {
  title: string;
  image: string;
  content: string;
}

interface DiscountTemplate extends Template {
  package_coupon_id?: number;
  select_coupon_id?: number;
}

interface TutorInfo {
  id: number;
  name: string;
  image: string;
}

interface BookInfo {
  book_image: string;
  book_name: string;
  book_publisher: string;
  book_detail: string;
  book_link: string;
}

interface MediaInfo {
  image: string;
  name: string;
  detail: string;
  link: string;
}

interface ExperimentalInfo {
  image: string;
  name: string;
  detail: string;
  link: string;
}

interface ProgramDetailProps {
  id: number;
  program_header: string;
  program_name: string;
  program_image: string;
  program_description: string;
  program_description_internal: string;
  program_detail_book_preview: string;
  program_detail_learns: string[];
  program_detail_which_members: string;
  program_progress_programs: string[];
  program_progresses: string[];
  combined_program_type_name: string;
  books: BookInfo[];
  medias: MediaInfo[];
  experimentals: ExperimentalInfo[];
  dates: string[];
  location: string;
  min_people: number;
  max_people: number;
  price: number;
  prev_price: number;
  price_description: string;
  tutors: TutorInfo[];
  tutor_speech: string;
  discount_templates: DiscountTemplate[];
  refund_templates: Template[];
  faq_templates: Template[];
  program_status: ProgramStatus;
  cart: boolean;
  starting_hour: number;
  starting_minute: number;
  minutes: number;
  bought: boolean;
  program_reviews: AdminUserReview[];
  other_programs: ProgramProps[];
  guidance: string;
  benefit_template?: Template;
  events: ProgramEvent[];
  program_type_details: string[];
  program_genres: string[];
  program_label: string;
  waiting_available: boolean;
  waiting: boolean;
  available_seats?: number;
  custom_date: boolean;
  custom_date_start: string;
  custom_date_end: string;
  banner_image: string;
  user_coupons: {
    coupon_id: number;
    user_id: number;
    used: boolean;
  }[];
}

interface LabelingButtonProps {
  programDetail: ProgramDetailProps;
  patchProgramWait: () => void;
  patchProgramCart: () => void;
  WaitDialog: () => JSX.Element;
  handleRegister: () => void;
  noCart?: boolean;
}

function LabelingButton({
  programDetail,
  patchProgramWait,
  patchProgramCart,
  WaitDialog,
  handleRegister,
  noCart,
}: LabelingButtonProps) {
  return (
    <div style={{ display: "flex" }}>
      {
        // do cart thing
        !noCart &&
          programDetail.program_status !== "done" &&
          !programDetail.bought && (
            <div style={{ flex: 1 }}>
              <CustomButton
                fullWidth
                style={{
                  backgroundColor: "white",
                  color: "#6939CD",
                  borderRadius: 0,
                  fontSize: 16,
                }}
                onClick={patchProgramCart}
              >
                {!programDetail.cart ? "장바구니" : "장바구니 취소"}
              </CustomButton>
            </div>
          )
      }
      <div style={{ flex: 1 }}>
        {(() => {
          let backgroundColor = "#5E10AC";
          let text = "신청하기";
          let disabled = false;
          let handleClick = handleRegister;
          if (programDetail.program_status !== "done") {
            // check if bought
            if (programDetail.bought) {
              text = "신청완료";
              disabled = true;
              backgroundColor = "#111111";
            }
            // check if available seats is in map
            if (
              programDetail.available_seats &&
              programDetail.available_seats <= 3
            ) {
              text = `마감까지 ${programDetail.available_seats}자리`;
            }
          } else {
            // need to check if status is done..
            if (programDetail.waiting_available) {
              if (!programDetail.waiting) {
                backgroundColor = "#310566";
                text = "마감 | 대기신청 가능";
              } else {
                backgroundColor = "#111111";
                text = "대기신청 취소";
              }
              handleClick = patchProgramWait;
            } else {
              backgroundColor = "#111111";
              text = "마감";
              disabled = true;
            }
          }
          return (
            <CustomButton
              fullWidth
              disabled={disabled}
              style={{
                backgroundColor,
                color: "white",
                borderRadius: 0,
                fontSize: 16,
              }}
              onClick={handleClick}
            >
              {text}
            </CustomButton>
          );
        })()}
      </div>
      <WaitDialog />
    </div>
  );
}

export function useProgramWait() {
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const patchProgramWait = useCallback((programId: number) => {
    return fetchDefaultWithCredential(
      `/program/${programId}/wait`,
      "PATCH"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(({ waiting }) => {
        if (waiting) {
          setOpen(true);
        }
      });
    });
  }, []);

  const WaitDialog = useCallback(() => {
    return (
      <CustomDialog open={open}>
        <div className="text-align-center">
          <p className="font-30 font-bold">대기 신청이 완료되었습니다</p>
          <p className="font-sub2 text-secondary" style={{ lineHeight: 2.5 }}>
            자리가 생기면 대기 신청을 하신분들께 '이메일'로 알림 메세지가 전송될
            예정입니다.
            <br />
            마이페이지에서 '마케팅 수신동의'에 체크해 주신분들께 여석 연락을
            보내드릴 수 있습니다.
          </p>
          <div className="mt-10">
            <Grid container columnSpacing={1} justifyContent="center">
              <Grid item>
                <CustomButton
                  outlined
                  onClick={() => history.push("/mypage?section=profile")}
                >
                  마케팅 수신동의 하러가기
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton outlined onClick={() => setOpen(false)}>
                  닫기
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </CustomDialog>
    );
  }, [open, history]);

  return {
    patchProgramWait,
    WaitDialog,
  };
}

function ProgramDetail() {
  const location = useLocation();
  const splittedLocation = location.pathname.split("/");
  const programId = parseInt(splittedLocation[splittedLocation.length - 1]);
  const [programDetail, setProgramDetail] = useState<ProgramDetailProps | null>(
    null
  );
  const [refresh, setRefresh] = useState<number>(0);
  const { showSuccessSnackbar, showWarningSnackbar } =
    useContext(SnackbarContext);
  const { patchProgramWait, WaitDialog } = useProgramWait();
  const history = useHistory();
  const user = useUser();
  const { signinWrapper, toggleSigninDialog, refreshUser } =
    useContext(GlobalContext);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  useEffect(() => {
    if (!programDetail) return;
    window.scrollTo(0, 0);
  }, [programDetail]);

  useEffect(() => {
    fetchDefaultWithCredential(`/program/${programId}`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setProgramDetail);
    });
  }, [programId, refresh]);

  const patchProgramCart = () => {
    if (programDetail) {
      fetchDefaultWithCredential(
        `/program/${programDetail.id}/cart`,
        "PATCH"
      ).then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(() => {
          if (!programDetail.cart) {
            showSuccessSnackbar("장바구니에 담겼습니다");
          } else {
            showWarningSnackbar("장바구니에서 제거되었습니다");
          }
          setRefresh(refresh + 1);
        });
      });
    }
  };

  const downloadCoupon = (couponId: number) => {
    return fetchDefaultWithCredential(`/coupons/${couponId}/download`, "GET")
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            showWarningSnackbar(error);
          });
        }
        return res.json().then(({ message }) => {
          showSuccessSnackbar(message);
        });
      })
      .then(refreshUser);
  };

  if (!programDetail) return null;

  return (
    <>
      <div
        style={{
          height: isMobile() ? 300 : 600,
          backgroundImage: `url(${programDetail.banner_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        style={{
          width: isMobile() ? undefined : 1180,
          margin: isMobile() ? undefined : "50px auto",
          padding: isMobile() ? 15 : undefined,
        }}
      >
        <div style={{ display: "flex", columnGap: 10 }}>
          {[
            ...programDetail.program_genres,
            ...programDetail.program_type_details,
            programDetail.location,
          ].map((word, index) => (
            <div
              style={{
                borderRadius: 9,
                border: "1.5px solid black",
                padding: "2.5px 5px",
                fontSize: isMobile() ? 14 : 20,
                fontWeight: 600,
              }}
              key={index}
            >
              #{word}
            </div>
          ))}
        </div>
        <div
          style={{
            marginTop: isMobile() ? 15 : 50,
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
          }}
        >
          <div style={{ width: isMobile() ? undefined : 850 }}>
            <div
              style={{
                color: "#5617A5",
                fontSize: isMobile() ? 26 : 36,
                fontWeight: 700,
              }}
            >
              {programDetail.program_name}
            </div>
            <div
              style={{
                marginTop: isMobile() ? 30 : 50,
                width: isMobile() ? 50 : 70,
                height: 10,
                backgroundColor: "#D9D9D9",
              }}
            ></div>
            <div
              style={{
                marginTop: 30,
                fontSize: isMobile() ? 14 : 17,
                whiteSpace: "pre-line",
              }}
            >
              {programDetail.program_description_internal}
            </div>
            {programDetail.books.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    fontSize: isMobile() ? 22 : 28,
                    fontWeight: 700,
                  }}
                >
                  어떤 책들을 읽나요?
                </div>
                <div style={{ marginTop: isMobile() ? 200 : 250 }}>
                  <ClickIndexComponent
                    height={isMobile() ? 155 : 190}
                    images={programDetail.books.map((book, index) => (
                      <div
                        key={index}
                        style={{
                          width: isMobile() ? 140 : 180,
                          height: isMobile() ? 215 : 260,
                          backgroundImage: `url(${book.book_image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(book.book_link, "_blank")}
                      ></div>
                    ))}
                    labels={programDetail.books.map((book, index) => (
                      <div key={index}>
                        <div
                          style={{
                            fontSize: isMobile() ? 18 : 20,
                            fontWeight: 700,
                          }}
                        >
                          {book.book_name}
                        </div>
                        <div
                          style={{
                            fontSize: isMobile() ? 12 : 14,
                            fontWeight: 500,
                          }}
                        >
                          ({book.book_publisher})
                        </div>
                      </div>
                    ))}
                    descriptions={programDetail.books.map((book, index) => (
                      <div
                        key={index}
                        style={{
                          whiteSpace: "pre-line",
                          fontSize: isMobile() ? 14 : 15,
                          padding: "0px 50px",
                        }}
                      >
                        {book.book_detail}
                      </div>
                    ))}
                  />
                </div>
              </>
            )}
            {programDetail.medias.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    fontSize: isMobile() ? 22 : 28,
                    fontWeight: 700,
                  }}
                >
                  어떤 매체를 함께 보나요?
                </div>
                <div style={{ marginTop: isMobile() ? 150 : 350 }}>
                  <ClickIndexComponent
                    height={isMobile() ? 155 : 160}
                    fullImages={programDetail.medias.map((media, index) => (
                      <div
                        key={index}
                        style={{
                          width: isMobile() ? 320 : 630,
                          height: isMobile() ? 180 : 350,
                          backgroundImage: `url(${media.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(media.link, "_blank")}
                      ></div>
                    ))}
                    labels={programDetail.medias.map((media, index) => (
                      <div key={index}>
                        <div
                          style={{
                            fontSize: isMobile() ? 18 : 20,
                            fontWeight: 700,
                          }}
                        >
                          {media.name}
                        </div>
                      </div>
                    ))}
                    descriptions={programDetail.medias.map((media, index) => (
                      <div
                        key={index}
                        style={{
                          whiteSpace: "pre-line",
                          fontSize: isMobile() ? 14 : 15,
                          padding: "0px 50px",
                        }}
                      >
                        {media.detail}
                      </div>
                    ))}
                  />
                </div>
              </>
            )}
            {programDetail.experimentals.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    fontSize: isMobile() ? 22 : 28,
                    fontWeight: 700,
                  }}
                >
                  어떤 활동을 함께 하나요?
                </div>
                <div style={{ marginTop: isMobile() ? 200 : 250 }}>
                  <ClickIndexComponent
                    height={isMobile() ? 155 : 190}
                    images={programDetail.experimentals.map(
                      (experimental, index) => (
                        <div
                          key={index}
                          style={{
                            width: isMobile() ? 140 : 180,
                            height: isMobile() ? 215 : 260,
                            backgroundImage: `url(${experimental.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(experimental.link, "_blank")
                          }
                        ></div>
                      )
                    )}
                    labels={programDetail.experimentals.map(
                      (experimental, index) => (
                        <div key={index}>
                          <div
                            style={{
                              fontSize: isMobile() ? 18 : 20,
                              fontWeight: 700,
                            }}
                          >
                            {experimental.name}
                          </div>
                        </div>
                      )
                    )}
                    descriptions={programDetail.experimentals.map(
                      (experimental, index) => (
                        <div
                          key={index}
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: isMobile() ? 14 : 15,
                            padding: "0px 50px",
                          }}
                        >
                          {experimental.detail}
                          <div
                            style={{
                              marginTop: isMobile() ? 15 : 30,
                              color: "#767676",
                            }}
                          >
                            활동에 관한 자세한 설명은 아래 사이트에서
                            확인하세요!
                          </div>
                          <div>
                            <a href={experimental.link}>{experimental.link}</a>
                          </div>
                        </div>
                      )
                    )}
                  />
                </div>
              </>
            )}
            <div
              style={{
                marginTop: isMobile() ? 30 : 50,
                width: isMobile() ? 50 : 70,
                height: 10,
                backgroundColor: "#D9D9D9",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                marginTop: isMobile() ? 15 : 50,
                flexDirection: isMobile() ? "column" : "row",
              }}
            >
              <div
                style={{
                  flex: 1,
                  fontSize: isMobile() ? 22 : 28,
                  fontWeight: 700,
                }}
              >
                세미나는
                <br />
                어떻게 진행되나요?
              </div>
              <div
                style={{
                  flex: 1,
                  fontSize: 16,
                  fontWeight: 700,
                  marginTop: isMobile() ? 30 : undefined,
                  alignSelf: isMobile() ? "center" : undefined,
                }}
              >
                <div style={{ fontSize: 15, fontWeight: 700 }}>
                  날짜 |{" "}
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {(() => {
                      // check if customized date or not
                      if (programDetail.custom_date) {
                        return `${formatDashedMonthDate(
                          programDetail.custom_date_start
                        )} - ${formatDashedMonthDate(
                          programDetail.custom_date_end
                        )}`;
                      }
                      return formatProgramDetailDates(
                        programDetail.dates.sort()
                      );
                    })()}
                  </span>
                </div>
                <div style={{ fontSize: 15, fontWeight: 700, marginTop: 10 }}>
                  시간 |{" "}
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {formatProgramTime(
                      programDetail.starting_hour,
                      programDetail.starting_minute,
                      programDetail.minutes
                    )}
                  </span>
                </div>
                <div style={{ fontSize: 15, fontWeight: 700, marginTop: 10 }}>
                  장소 |{" "}
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {programDetail.location}
                  </span>
                </div>
                <div style={{ fontSize: 15, fontWeight: 700, marginTop: 10 }}>
                  인원 |{" "}
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    최소 {programDetail.min_people}명 ~ 최대{" "}
                    {programDetail.max_people}명
                  </span>
                </div>
                <div style={{ fontSize: 15, fontWeight: 700, marginTop: 10 }}>
                  튜터 |{" "}
                  <span>
                    {programDetail.tutors.map((tutor) => tutor.name).join(", ")}
                  </span>
                </div>
                <div style={{ fontSize: 15, fontWeight: 700, marginTop: 10 }}>
                  가격 |{" "}
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {programDetail.prev_price !== 0 && (
                      <Fragment>
                        <span
                          style={{
                            color:
                              programDetail.prev_price !== 0
                                ? "#5d5d5d"
                                : "inherit",
                            textDecoration:
                              programDetail.prev_price !== 0
                                ? "line-through"
                                : "inherit",
                          }}
                        >
                          {programDetail.prev_price.toLocaleString()}원
                        </span>
                        <span> → </span>
                      </Fragment>
                    )}
                    <span style={{ fontSize: 15, fontWeight: 700 }}>
                      {programDetail.price.toLocaleString()}원
                    </span>
                    {programDetail.price_description !== "" && (
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: 15,
                          fontWeight: 500,
                          color: "#5d5d5d",
                        }}
                      >
                        ({programDetail.price_description})
                      </span>
                    )}
                  </span>
                </div>
                {programDetail.guidance !== "" && (
                  <div
                    style={{
                      marginTop: 10,
                      fontSize: 15,
                      fontWeight: 700,
                      whiteSpace: "pre-line",
                    }}
                  >
                    별도 안내 |{" "}
                    <span
                      style={{
                        marginTop: 10,
                        fontSize: 15,
                        fontWeight: 500,
                        whiteSpace: "pre-line",
                      }}
                    >
                      {programDetail.guidance}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <ProgramProgressComponent
                seminarNames={programDetail.program_progress_programs}
                seminarDates={programDetail.dates}
                progressDescriptions={programDetail.program_progresses}
              />
            </div>
            <div style={{ marginTop: 50 }}>
              <div
                style={{
                  boxShadow: "0px 2px 14.7px 0px #0000001F",
                  borderRadius: "0px 50px 0px 50px",
                  padding: isMobile() ? 30 : 50,
                  display: "flex",
                  columnGap: isMobile() ? 30 : 50,
                  flexDirection: isMobile() ? "column" : "row",
                  alignItems: isMobile() ? "center" : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  >
                    <CustomAvatarWithLocation
                      location={programDetail.tutors[0].image}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: 15,
                      fontSize: isMobile() ? 15 : 16,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    튜터{" "}
                    <span
                      style={{
                        fontWeight: 700,
                        color: "#4A148C",
                      }}
                    >
                      {programDetail.tutors[0].name}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: 15,
                      textDecoration: "underline",
                      color: "#505050",
                      cursor: "pointer",
                      fontSize: 10,
                      fontWeight: 500,
                    }}
                    onClick={() =>
                      history.push("/tutor/" + programDetail.tutors[0].id)
                    }
                  >
                    {"프로필 및 멤버 후기 보러가기 >>"}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    fontSize: isMobile() ? 14 : 15,
                    whiteSpace: "pre-line",
                    marginTop: isMobile() ? 30 : undefined,
                  }}
                >
                  {programDetail.tutor_speech}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: isMobile() ? 30 : 50,
                width: isMobile() ? 50 : 70,
                height: 10,
                backgroundColor: "#D9D9D9",
              }}
            ></div>
            <div
              style={{
                marginTop: isMobile() ? 15 : 50,
                fontSize: isMobile() ? 22 : 28,
                fontWeight: 700,
              }}
            >
              어떤 멤버들과 함께 하나요?
            </div>
            <div
              style={{
                marginTop: isMobile() ? 15 : 50,
                borderRadius: 20,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: 300,
                  backgroundImage: `url(${ProgramDetailMemberPng})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div
                style={{
                  padding: isMobile() ? 25 : 50,
                  backgroundColor: "#F4F4F4",
                  fontSize: isMobile() ? 14 : 15,
                  whiteSpace: "pre-line",
                }}
              >
                {programDetail.program_detail_which_members}
              </div>
            </div>
            {programDetail.program_detail_learns.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    fontSize: isMobile() ? 22 : 28,
                    fontWeight: 700,
                  }}
                >
                  무엇을 배울 수 있나요?
                </div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    display: "flex",
                    columnGap: isMobile() ? undefined : 10,
                    flexDirection: isMobile() ? "column" : "row",
                    rowGap: isMobile() ? 10 : undefined,
                  }}
                >
                  {programDetail.program_detail_learns.map(
                    (program_detail_learn, index) => (
                      <div
                        key={index}
                        style={{
                          flex: 1,
                          alignSelf: "stretch",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: isMobile() ? 15 : undefined,
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            color: "#4A148C",
                            fontSize: isMobile() ? 15 : 22,
                            fontWeight: 700,
                          }}
                        >
                          POINT {index + 1}.
                        </div>
                        <div
                          style={{
                            flex: 1,
                            marginTop: isMobile() ? 15 : 30,
                            padding: 30,
                            backgroundColor: "#F4F4F4",
                            borderRadius: 20,
                            whiteSpace: "pre-line",
                            fontSize: 14,
                          }}
                        >
                          {program_detail_learn}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            )}
            {programDetail.benefit_template && (
              <>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    fontSize: isMobile() ? 22 : 28,
                    fontWeight: 700,
                  }}
                >
                  다양한 혜택이 기다리고 있어요!
                </div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                  }}
                >
                  {(programDetail.benefit_template.meta.benefits as any[]).map(
                    (benefit, index) => (
                      <div
                        key={index}
                        style={{
                          marginTop: isMobile() ? 15 : 50,
                          backgroundColor: "#F1F1FA",
                          borderRadius: 20,
                          padding: isMobile() ? 30 : 50,
                        }}
                      >
                        <div
                          style={{
                            fontSize: isMobile() ? 18 : 21,
                            fontWeight: 700,
                          }}
                        >
                          {benefit.title}
                        </div>
                        <div style={{ marginTop: 10, fontSize: 14 }}>
                          {benefit.description}
                        </div>
                        <div
                          style={{
                            marginTop: isMobile() ? 15 : 30,
                            display: "flex",
                          }}
                        >
                          <img
                            src={benefit.image}
                            alt=""
                            draggable={false}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            )}
            {programDetail.discount_templates.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    marginTop: isMobile() ? 15 : 50,
                    flexDirection: isMobile() ? "column" : "row",
                  }}
                >
                  <div
                    style={{
                      fontSize: isMobile() ? 22 : 28,
                      fontWeight: 700,
                    }}
                  >
                    필로어스가
                    <br />
                    준비한
                    <br />
                    특별한 할인!
                  </div>
                  <div
                    style={{
                      marginTop: isMobile() ? 30 : undefined,
                      marginLeft: isMobile() ? undefined : 100,
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      rowGap: isMobile() ? 15 : 50,
                    }}
                  >
                    {programDetail.discount_templates.map(
                      (discount_template, index) => (
                        <div
                          key={index}
                          style={{
                            width: isMobile() ? undefined : 500,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            userSelect: "none",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              width: isMobile() ? "80%" : "80%",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!user) return toggleSigninDialog();
                              if (
                                user.coupons.find(
                                  (coupon) =>
                                    coupon.template_id === discount_template.id
                                )
                              )
                                return;
                              downloadCoupon(
                                discount_template.package_coupon_id ||
                                  discount_template.select_coupon_id ||
                                  0
                              );
                            }}
                          >
                            {user &&
                              user.coupons.find(
                                (coupon) =>
                                  coupon.template_id === discount_template.id
                              ) && (
                                <div
                                  style={{
                                    position: "absolute",
                                    inset: isMobile()
                                      ? "0px 5px 20px 0px"
                                      : "0px 15px 8px 10px",
                                    backgroundColor: "#FFFFFFB2",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: 1,
                                    cursor: "default",
                                  }}
                                >
                                  쿠폰 받기가 완료되었습니다.
                                </div>
                              )}
                            <CouponSvg style={{ width: "100%" }} />
                            <div
                              style={{
                                position: "absolute",
                                left: isMobile() ? 5 : 10,
                                bottom: isMobile() ? 50 : 35,
                                right: isMobile() ? 100 : 110,
                                textAlign: "center",
                                color: "#00000080",
                                fontSize: isMobile() ? 35 : 45,
                                fontWeight: 700,
                              }}
                            >
                              {discount_template.meta.amount}
                            </div>
                          </div>
                          <div
                            style={{
                              zIndex: -1,
                              marginTop: -80,
                              backgroundColor: "#F1F1F1F0",
                              borderTopLeftRadius: 100,
                              borderTopRightRadius: 100,
                              padding: 30,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 50,
                                backgroundColor: "white",
                                borderRadius: 17.5,
                                padding: 15,
                                color: "#6939CD",
                                fontSize: 18,
                                fontWeight: 700,
                              }}
                            >
                              {discount_template.meta.title}
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                                fontSize: isMobile() ? 14 : 15,
                                textAlign: "start",
                              }}
                            >
                              {discount_template.meta.description}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            )}
            {programDetail.events.map((event, index) => (
              <Fragment key={index}>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    fontSize: isMobile() ? 22 : 28,
                    fontWeight: 700,
                  }}
                >
                  {event.title}
                </div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    borderRadius: 20,
                    overflow: "hidden",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      height: 300,
                      backgroundImage: `url(${event.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <div
                    style={{
                      padding: isMobile() ? 25 : 50,
                      backgroundColor: "#F4F4F4",
                      fontSize: isMobile() ? 14 : 15,
                      whiteSpace: "pre-line",
                    }}
                  >
                    {event.content}
                  </div>
                </div>
              </Fragment>
            ))}
            {programDetail.faq_templates.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: isMobile() ? 30 : 50,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    marginTop: isMobile() ? 15 : 50,
                    flexDirection: isMobile() ? "column" : "row",
                  }}
                >
                  <div
                    style={{
                      fontSize: 28,
                      fontWeight: 700,
                    }}
                  >
                    자주 묻는
                    <br />
                    질문
                  </div>
                  <div
                    style={{
                      marginTop: isMobile() ? 30 : undefined,
                      marginLeft: isMobile() ? undefined : 100,
                      flex: 1,
                    }}
                  >
                    {programDetail.faq_templates.map((faq_template, index) => (
                      <Accordion key={index} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <div
                            style={{
                              fontSize: 18,
                              fontWeight: 500,
                            }}
                          >
                            {faq_template.meta.question}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{
                              fontSize: isMobile() ? 14 : 15,
                              fontWeight: 400,
                            }}
                          >
                            {faq_template.meta.answer}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div
              style={{
                marginTop: isMobile() ? 30 : 50,
                width: isMobile() ? 50 : 70,
                height: 10,
                backgroundColor: "#D9D9D9",
              }}
            ></div>
            <div
              style={{
                marginTop: isMobile() ? 15 : 50,
                fontSize: isMobile() ? 22 : 28,
                fontWeight: 700,
              }}
            >
              환불규정은 어떻게 되나요?
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
                margin: "0px auto",
                padding: "75px 0px",
              }}
            >
              <div
                style={{
                  width: isMobile() ? 16.5 : 25,
                  height: isMobile() ? 16.5 : 25,
                  borderRadius: "50%",
                  border: "1.5px solid #767676",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    color: "#6939CD",
                    fontWeight: 700,
                    fontSize: isMobile() ? 12 : 16,
                    position: "absolute",
                    bottom: "150%",
                  }}
                >
                  전액 환불
                </div>
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    fontSize: isMobile() ? 12 : 16,
                    fontWeight: 500,
                    position: "absolute",
                    top: "150%",
                  }}
                >
                  모임시작
                  <br />
                  8일 전
                </div>
              </div>
              <div
                style={{ flex: 1, height: 2, backgroundColor: "#767676" }}
              ></div>
              <div
                style={{
                  width: isMobile() ? 16.5 : 25,
                  height: isMobile() ? 16.5 : 25,
                  borderRadius: "50%",
                  border: "1.5px solid #767676",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    color: "#6939CD",
                    fontWeight: 700,
                    fontSize: isMobile() ? 12 : 16,
                    position: "absolute",
                    bottom: "150%",
                  }}
                >
                  50% 환불
                </div>
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    fontSize: isMobile() ? 12 : 16,
                    fontWeight: 500,
                    position: "absolute",
                    top: "150%",
                  }}
                >
                  모임시작
                  <br />
                  7~6일 전
                </div>
              </div>
              <div
                style={{ flex: 1, height: 2, backgroundColor: "#767676" }}
              ></div>
              <div
                style={{
                  width: isMobile() ? 16.5 : 25,
                  height: isMobile() ? 16.5 : 25,
                  borderRadius: "50%",
                  border: "1.5px solid #767676",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    color: "#6939CD",
                    fontWeight: 700,
                    fontSize: isMobile() ? 12 : 16,
                    position: "absolute",
                    bottom: "150%",
                  }}
                >
                  25% 환불
                </div>
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    fontSize: isMobile() ? 12 : 16,
                    fontWeight: 500,
                    position: "absolute",
                    top: "150%",
                  }}
                >
                  모임시작
                  <br />
                  5~4일 전
                </div>
              </div>
              <div
                style={{ flex: 1, height: 2, backgroundColor: "#767676" }}
              ></div>
              <div
                style={{
                  width: isMobile() ? 16.5 : 25,
                  height: isMobile() ? 16.5 : 25,
                  borderRadius: "50%",
                  border: "1.5px solid #767676",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    color: "#6939CD",
                    fontWeight: 700,
                    fontSize: isMobile() ? 12 : 16,
                    position: "absolute",
                    bottom: "150%",
                  }}
                >
                  환불 불가
                </div>
                <div
                  style={{
                    width: 100,
                    right: "50%",
                    transform: "translateX(50%)",
                    textAlign: "center",
                    fontSize: isMobile() ? 12 : 16,
                    fontWeight: 500,
                    position: "absolute",
                    top: "150%",
                  }}
                >
                  모임시작
                  <br />
                  3일 전~당일
                </div>
              </div>
            </div>
            {programDetail.other_programs.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: 30,
                    width: isMobile() ? 50 : 70,
                    height: 10,
                    backgroundColor: "#D9D9D9",
                  }}
                ></div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                    fontSize: isMobile() ? 22 : 28,
                    fontWeight: 700,
                  }}
                >
                  어떤 다른 프로그램이 있을까요?
                </div>
                <div
                  style={{
                    marginTop: isMobile() ? 15 : 50,
                  }}
                >
                  <CustomSwiper slides={isMobile() ? 2 : 3} noNav>
                    {programDetail.other_programs.map(
                      (other_program, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <ProgramComponent program={other_program} />
                        </div>
                      )
                    )}
                  </CustomSwiper>
                </div>
              </>
            )}
          </div>
          {!isMobile() && (
            <div style={{ marginLeft: "auto" }}>
              <div
                style={{
                  position: "sticky",
                  top: 100,
                }}
              >
                <SummaryProgramCard
                  programDetail={programDetail}
                  patchProgramWait={() =>
                    signinWrapper(() =>
                      patchProgramWait(programDetail.id).then(() =>
                        setRefresh(refresh + 1)
                      )
                    )
                  }
                  patchProgramCart={() => signinWrapper(patchProgramCart)}
                  WaitDialog={WaitDialog}
                  handleRegister={() =>
                    signinWrapper(() =>
                      history.push({
                        pathname: "/order",
                        state: [programDetail.id],
                      })
                    )
                  }
                />
              </div>
            </div>
          )}
          {isMobile() && (
            <>
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  marginLeft: -15,
                  marginRight: -15,
                  marginBottom: -15,
                  zIndex: 10,
                }}
              >
                <LabelingButton
                  programDetail={programDetail}
                  patchProgramWait={() =>
                    signinWrapper(() =>
                      patchProgramWait(programDetail.id).then(() =>
                        setRefresh(refresh + 1)
                      )
                    )
                  }
                  patchProgramCart={() => signinWrapper(patchProgramCart)}
                  WaitDialog={WaitDialog}
                  handleRegister={() => setOpenDrawer(true)}
                />
              </div>
              <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                anchor="bottom"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <KeyboardArrowDownIcon
                      style={{
                        color: "#6939CD",

                        fontSize: 30,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <SummaryProgramCard
                      noCart
                      fullWidth
                      programDetail={programDetail}
                      patchProgramWait={() =>
                        signinWrapper(() =>
                          patchProgramWait(programDetail.id).then(() =>
                            setRefresh(refresh + 1)
                          )
                        )
                      }
                      patchProgramCart={() => signinWrapper(patchProgramCart)}
                      WaitDialog={WaitDialog}
                      handleRegister={() =>
                        signinWrapper(() =>
                          history.push({
                            pathname: "/order",
                            state: [programDetail.id],
                          })
                        )
                      }
                    />
                  </div>
                </div>
              </Drawer>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProgramDetail;
