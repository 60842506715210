import { Card, Grid } from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  fetchDefaultWithCredential,
  formatDashedDate,
} from "../../../../../utils";
import CustomButton from "../../../../customs/CustomButton";
import CustomDialog from "../../../../customs/CustomDialog";
import CustomImageUploadButton from "../../../../customs/CustomImageUploadButton";
import CustomText from "../../../../customs/CustomText";
import { postImageFile } from "../../../../../hooks/useImages";
import CustomCheckbox from "../../../../customs/CustomCheckbox";
import CustomSelect from "../../../../customs/CustomSelect";
import { Option, useOptions } from "../../Options";
import CustomCheck from "../../../../customs/CustomCheck";
import { Template, useTemplates } from "./Templates";
import { useTutors } from "../../../../../hooks";
import { Tutor } from "../../../../../interfaces";
import SortComponent from "../../helpers/SortComponent";
import { postFile, putFile } from "../../../../../hooks/useFiles";
import { useBooks } from "./Book";

interface ProgramBookComponentProps {
  adminProgram: AdminProgram;
}

function ProgramBookComponent({ adminProgram }: ProgramBookComponentProps) {
  const { books } = useBooks();

  const { refreshAdminPrograms } = useContext(AdminProgramsContext);

  const handleAddBook = () => {
    return fetchDefaultWithCredential(
      `/program/${adminProgram.id}/book`,
      "POST"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleEditBook = (programBook: ProgramBook) => {
    return fetchDefaultWithCredential(
      `/program/book/${programBook.id}`,
      "PUT",
      programBook
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleDeleteBook = (bookId: number) => {
    return fetchDefaultWithCredential(`/program/book/${bookId}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(refreshAdminPrograms);
      }
    );
  };

  if (!books) return null;

  return (
    <Fragment>
      <div className="d-flex justify-content-flex-end">
        <CustomButton onClick={handleAddBook}>책 추가</CustomButton>
      </div>
      {adminProgram.books.map((programBook) => (
        <Card variant="outlined" className="mt-10 p-30" key={programBook.id}>
          <div className="d-flex justify-content-flex-end">
            <CustomButton onClick={() => handleDeleteBook(programBook.id)}>
              삭제
            </CustomButton>
          </div>
          <div className="mt-10">
            <CustomSelect<number>
              label="책 선택"
              name="book"
              items={[
                {
                  label: "선택 안함",
                  value: 0,
                  disabled: true,
                },
                ...books.map((book) => ({
                  label: `${book.meta.name || "책이름"} (${
                    book.meta.publisher || "출판사"
                  })`,
                  value: book.id,
                })),
              ]}
              value={programBook.book_id || 0}
              handleChange={(book_id) => {
                programBook.book_id = book_id;
                handleEditBook(programBook);
              }}
            />
          </div>
          {(() => {
            const book = books.find((book) => book.id === programBook.book_id);
            if (!book) return null;
            return (
              <div className="mt-10 font-14">
                <div>
                  <img src={book.image} alt="" className="height-100" />
                </div>
                <div className="mt-10">{book.meta.name}</div>
                <div className="mt-10">{book.meta.publisher}</div>
                <div className="mt-10">{book.meta.author}</div>
                <div className="mt-10">{book.meta.detail}</div>
              </div>
            );
          })()}
        </Card>
      ))}
    </Fragment>
  );
}

interface ProgramGroup {
  id: number;
  name: string;
  visualize: boolean;
  program_count: number;
  description: string;
}

interface ProgramGroupListComponentProps {
  programGroup: ProgramGroup;
}

function ProgramGroupListComponent({
  programGroup,
}: ProgramGroupListComponentProps) {
  const { refreshProgramGroups } = useContext(ProgramGroupsContext);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const handleDelete = (id: number) => {
    return fetchDefaultWithCredential(`/program/groups/${id}`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(refreshProgramGroups);
      }
    );
  };

  const handleEdit = () => {
    return fetchDefaultWithCredential(
      `/program/groups/${programGroup.id}`,
      "PUT",
      programGroup
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshProgramGroups);
    });
  };
  return (
    <Fragment>
      <Card variant="outlined" className="p-30">
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <CustomCheckbox
              checked={programGroup.visualize}
              handleChange={(checked) => {
                programGroup.visualize = checked;
                handleEdit();
              }}
              disabled={programGroup.id === 0}
            >
              보여지기
            </CustomCheckbox>
          </Grid>
          <Grid item>
            <CustomButton
              onClick={() => setOpenDelete(true)}
              disabled={programGroup.id === 0}
            >
              삭제
            </CustomButton>
          </Grid>
        </Grid>
        <div className="mt-15">
          <CustomText
            label="그룹 이름"
            name="name"
            type="text"
            width="100%"
            defaultValue={programGroup.name}
            handleBlur={(e) => {
              programGroup.name = e.target.value;
              handleEdit();
            }}
            disabled={programGroup.id === 0}
          />
        </div>
        <div className="mt-15">
          <CustomText
            label="그룹 설명"
            name="description"
            type="text"
            width="100%"
            defaultValue={programGroup.description}
            handleBlur={(e) => {
              programGroup.description = e.target.value;
              handleEdit();
            }}
            disabled={programGroup.id === 0}
          />
        </div>
      </Card>
      <CustomDialog open={openDelete}>
        <p className="font-header">정말로 삭제 하시겠습니까?</p>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CustomButton onClick={() => handleDelete(programGroup.id)}>
              삭제
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton onClick={() => setOpenDelete(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
    </Fragment>
  );
}

function ProgramGroupComponent() {
  const { programGroups, refreshProgramGroups } =
    useContext(ProgramGroupsContext);
  const handleAdd = () => {
    return fetchDefaultWithCredential(`/program/groups`, "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshProgramGroups);
    });
  };

  return (
    <Card className="p-30">
      <div className="d-flex justify-content-space-between">
        <div className="font-header">프로그램 그룹</div>
        <CustomButton onClick={handleAdd}>추가</CustomButton>
      </div>
      <div className="program-groups">
        {programGroups.map((programGroup) => (
          <div className="mt-30" key={programGroup.id}>
            <ProgramGroupListComponent programGroup={programGroup} />
          </div>
        ))}
      </div>
    </Card>
  );
}

interface TutorTypeComponentProps {
  tutors: Tutor[];
  program_id: number;
  tutor?: Tutor;
  type: string;
}

function TutorTypeComponent({
  tutors,
  program_id,
  tutor,
  type,
}: TutorTypeComponentProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [currentTutor, setCurrentTutor] = useState<Tutor | null>(null);
  const { refreshAdminPrograms } = useContext(AdminProgramsContext);

  const handleDelete = () => {
    return fetchDefaultWithCredential(
      `/program/${program_id}/tutor?type=${type}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleRegister = () => {
    if (currentTutor) {
      fetchDefaultWithCredential(
        `/program/${program_id}/tutor?type=${type}`,
        "PUT",
        {
          tutor_id: currentTutor.id,
        }
      ).then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(refreshAdminPrograms);
      });
    }
    setOpen(false);
  };

  return (
    <Fragment>
      {tutor && (
        <Fragment>
          <div
            className="width-50 height-50"
            style={{
              backgroundImage: `url(${tutor.tutor_avatar})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "50%",
            }}
          ></div>
          <div className="mt-10">
            <p className="font-16 font-bold">{tutor.name}</p>
          </div>
        </Fragment>
      )}
      <div className="mt-10">
        <CustomButton onClick={() => setOpen(true)}>튜터 수정</CustomButton>
      </div>
      <div className="mt-10">
        <CustomButton onClick={handleDelete}>튜터 삭제</CustomButton>
      </div>
      <CustomDialog open={open}>
        <SortComponent
          currentDataObject={currentTutor}
          setCurrentDataObject={setCurrentTutor}
          label="튜터"
          data={tutors}
          listItemComponent={(tutor) => (
            <div>
              {tutor.user_id}. {tutor.name}
            </div>
          )}
          abcSortAttributeFunc={(tutor) => tutor.name}
        />
        <div className="mt-10 d-flex justify-content-flex-end">
          <div>
            <CustomButton onClick={handleRegister}>등록</CustomButton>
          </div>
          <div className="ml-10">
            <CustomButton onClick={() => setOpen(false)}>취소</CustomButton>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

interface TutorComponentProps {
  program_id: number;
  main_tutor_id?: number;
  sub_tutor_id?: number;
  trainee_tutor_id?: number;
}

function TutorComponent({
  program_id,
  main_tutor_id,
  sub_tutor_id,
  trainee_tutor_id,
}: TutorComponentProps) {
  const { tutors } = useTutors();
  const mainTutor = tutors.find((tutor) => tutor.id === main_tutor_id);
  const subTutor = tutors.find((tutor) => tutor.id === sub_tutor_id);
  const traineeTutor = tutors.find((tutor) => tutor.id === trainee_tutor_id);

  return (
    <Fragment>
      <Grid container columnSpacing={1}>
        <Grid item xs>
          <p className="font-sub">메인</p>
          <TutorTypeComponent
            tutors={tutors}
            program_id={program_id}
            type="main"
            tutor={mainTutor}
          />
        </Grid>
        <Grid item xs>
          <p className="font-sub">서브</p>
          <TutorTypeComponent
            tutors={tutors}
            program_id={program_id}
            type="sub"
            tutor={subTutor}
          />
        </Grid>
        <Grid item xs>
          <p className="font-sub">견습</p>
          <TutorTypeComponent
            tutors={tutors}
            program_id={program_id}
            type="trainee"
            tutor={traineeTutor}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

interface IndividualProgramComponentProps {
  adminProgram: AdminProgram;
}

function IndividualProgramComponent({
  adminProgram,
}: IndividualProgramComponentProps) {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const { programGroups, refreshProgramGroups } =
    useContext(ProgramGroupsContext);
  const {
    programTypeDetailOptions,
    programGenreOptions,
    programLabelOptions,
    programTypeOptions,
    refreshAdminPrograms,
    templates,
  } = useContext(AdminProgramsContext);

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const handleDelete = () => {
    return fetchDefaultWithCredential(
      `/program/${adminProgram.id}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleEdit = () => {
    return fetchDefaultWithCredential(
      `/program/${adminProgram.id}`,
      "PUT",
      adminProgram
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleAddMedia = () => {
    return fetchDefaultWithCredential(
      `/program/${adminProgram.id}/media`,
      "POST"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleEditMedia = (media: ProgramMedia) => {
    return fetchDefaultWithCredential(
      `/program/media/${media.id}`,
      "PUT",
      media
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleDeleteMedia = (mediaId: number) => {
    return fetchDefaultWithCredential(
      `/program/media/${mediaId}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleAddExperimental = () => {
    return fetchDefaultWithCredential(
      `/program/${adminProgram.id}/experimental`,
      "POST"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleEditExperimental = (experimental: ProgramExperimental) => {
    return fetchDefaultWithCredential(
      `/program/experimental/${experimental.id}`,
      "PUT",
      experimental
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  const handleDeleteExperimental = (experimentalId: number) => {
    return fetchDefaultWithCredential(
      `/program/experimental/${experimentalId}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshAdminPrograms);
    });
  };

  return (
    <Fragment>
      <div className="p-30 d-flex justify-content-space-between">
        <div>
          <CustomCheckbox
            checked={adminProgram.visualize}
            handleChange={(checked) => {
              adminProgram.visualize = checked;
              handleEdit();
            }}
          >
            보여지기
          </CustomCheckbox>
        </div>
        <div>
          <CustomButton onClick={() => setOpenDelete(true)}>삭제</CustomButton>
        </div>
      </div>
      <div className="p-30">
        <CustomCheck
          label="프로그램 그룹"
          checkItems={programGroups
            .filter((programGroup) => programGroup.id !== 0)
            .map((programGroup) => ({
              label: programGroup.name,
              id: programGroup.id,
              checked: adminProgram.program_group_ids.includes(
                programGroup.id as number
              ),
            }))}
          handleChange={(checkItemId: any, checked: boolean) => {
            if (!checked) {
              adminProgram.program_group_ids =
                adminProgram.program_group_ids.filter(
                  (program_group_id) => program_group_id !== checkItemId
                );
            } else {
              adminProgram.program_group_ids = [
                ...adminProgram.program_group_ids,
                checkItemId,
              ];
            }
            handleEdit().then(refreshProgramGroups);
          }}
        />
      </div>
      <div className="p-30">
        <Grid container spacing={2}>
          <Grid item xs>
            <img
              src={adminProgram.program_image}
              alt=""
              className="width-100-percentage"
            />
          </Grid>
          <Grid item>
            <CustomImageUploadButton
              fileChangeCallback={(file) => {
                postImageFile(file).then((image) => {
                  adminProgram.program_image_id = image.id;
                  handleEdit();
                });
              }}
            >
              프로그램 이미지 선택
            </CustomImageUploadButton>
          </Grid>
        </Grid>
      </div>
      <div className="p-30">
        <Grid container spacing={2}>
          <Grid item xs>
            <img
              src={adminProgram.banner_image}
              alt=""
              className="width-100-percentage"
            />
          </Grid>
          <Grid item>
            <CustomImageUploadButton
              fileChangeCallback={(file) => {
                postImageFile(file).then((image) => {
                  adminProgram.banner_image_id = image.id;
                  handleEdit();
                });
              }}
            >
              프로그램 배너 선택
            </CustomImageUploadButton>
          </Grid>
        </Grid>
      </div>
      <div className="p-30">
        <p className="font-14">고전여부</p>
        <div className="d-flex">
          <CustomCheckbox
            checked={adminProgram.classic}
            handleChange={(checked) => {
              adminProgram.classic = checked;
              handleEdit();
            }}
            disabled={adminProgram.id === 0}
          >
            고전
          </CustomCheckbox>
        </div>
      </div>
      <div className="p-30">
        <CustomText
          label="프로그램 머릿말"
          name="program_header"
          type="text"
          width="100%"
          defaultValue={adminProgram.program_header}
          handleBlur={(e) => {
            adminProgram.program_header = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="프로그램 명"
          name="program_name"
          type="text"
          width="100%"
          defaultValue={adminProgram.program_name}
          handleBlur={(e) => {
            adminProgram.program_name = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomCheck
          label="프로그램 유형"
          checkItems={programTypeOptions.map((programTypeOption) => ({
            label: programTypeOption.label,
            id: programTypeOption.id,
            checked: adminProgram.program_type_ids.includes(
              programTypeOption.id as number
            ),
          }))}
          handleChange={(checkItemId: any, checked: boolean) => {
            if (!checked) {
              adminProgram.program_type_ids =
                adminProgram.program_type_ids.filter(
                  (program_type_id) => program_type_id !== checkItemId
                );
            } else {
              adminProgram.program_type_ids = [
                ...adminProgram.program_type_ids,
                checkItemId,
              ];
            }
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomCheck
          label="프로그램 장르"
          checkItems={programGenreOptions.map((programGenre) => ({
            label: programGenre.label,
            id: programGenre.id,
            checked: adminProgram.program_genre_ids.includes(
              programGenre.id as number
            ),
          }))}
          handleChange={(checkItemId: any, checked: boolean) => {
            if (!checked) {
              adminProgram.program_genre_ids =
                adminProgram.program_genre_ids.filter(
                  (program_genre_id) => program_genre_id !== checkItemId
                );
            } else {
              adminProgram.program_genre_ids = [
                ...adminProgram.program_genre_ids,
                checkItemId,
              ];
            }
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="프로그램 소개 (외부)"
          name="program_description"
          type="text"
          width="100%"
          multiline
          height="100px"
          defaultValue={adminProgram.program_description}
          handleBlur={(e) => {
            adminProgram.program_description = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="프로그램 소개 (내부)"
          name="program_description_internal"
          type="text"
          width="100%"
          multiline
          height="100px"
          defaultValue={adminProgram.program_description_internal}
          handleBlur={(e) => {
            adminProgram.program_description_internal = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="프로그램 소개 (어떤 멤버들과 함께 하나요?)"
          name="program_detail_which_members"
          type="text"
          width="100%"
          multiline
          height="100px"
          defaultValue={adminProgram.program_detail_which_members}
          handleBlur={(e) => {
            adminProgram.program_detail_which_members = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton
            onClick={() => {
              adminProgram.program_detail_learns = [
                ...adminProgram.program_detail_learns,
                "",
              ];
              handleEdit();
            }}
          >
            배워갈 수 있는 것 추가
          </CustomButton>
        </div>
        {adminProgram.program_detail_learns.map(
          (program_detail_learn, index) => (
            <div className="mt-10" key={index}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <CustomText
                    label="배워갈 수 있는 것"
                    name="program_detail_learn"
                    type="text"
                    width="100%"
                    defaultValue={program_detail_learn}
                    handleBlur={(e) => {
                      adminProgram.program_detail_learns[index] =
                        e.target.value;
                      handleEdit();
                    }}
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    onClick={() => {
                      adminProgram.program_detail_learns.splice(index, 1);
                      handleEdit();
                    }}
                  >
                    삭제
                  </CustomButton>
                </Grid>
              </Grid>
            </div>
          )
        )}
      </div>
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton
            onClick={() => {
              adminProgram.program_progress_programs = [
                ...adminProgram.program_progress_programs,
                "",
              ];
              handleEdit();
            }}
          >
            진행순서 프로그램 추가
          </CustomButton>
        </div>
        {adminProgram.program_progress_programs.map(
          (program_progress_program, index) => (
            <div className="mt-10" key={index}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <CustomText
                    label="진행순서 프로그램"
                    name="program_progress_program"
                    type="text"
                    width="100%"
                    defaultValue={program_progress_program}
                    handleBlur={(e) => {
                      adminProgram.program_progress_programs[index] =
                        e.target.value;
                      handleEdit();
                    }}
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    onClick={() => {
                      adminProgram.program_progress_programs.splice(index, 1);
                      handleEdit();
                    }}
                  >
                    삭제
                  </CustomButton>
                </Grid>
              </Grid>
            </div>
          )
        )}
      </div>
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton
            onClick={() => {
              adminProgram.program_progresses = [
                ...adminProgram.program_progresses,
                "",
              ];
              handleEdit();
            }}
          >
            진행순서 설명 추가
          </CustomButton>
        </div>
        {adminProgram.program_progresses.map((program_progress, index) => (
          <div className="mt-10" key={index}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <CustomText
                  label="진행순서 설명"
                  name="program_progress"
                  type="text"
                  width="100%"
                  height="100px"
                  multiline
                  defaultValue={program_progress}
                  handleBlur={(e) => {
                    adminProgram.program_progresses[index] = e.target.value;
                    handleEdit();
                  }}
                />
              </Grid>
              <Grid item>
                <CustomButton
                  onClick={() => {
                    adminProgram.program_progresses.splice(index, 1);
                    handleEdit();
                  }}
                >
                  삭제
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
      <div className="p-30">
        <CustomText
          label="장소"
          name="location"
          type="text"
          width="100%"
          defaultValue={adminProgram.location}
          handleBlur={(e) => {
            adminProgram.location = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomCheckbox
          border={false}
          checked={adminProgram.custom_date}
          handleChange={(checked) => {
            adminProgram.custom_date = checked;
            handleEdit();
          }}
        >
          고객에게 보여지는 날짜
        </CustomCheckbox>
      </div>
      {adminProgram.custom_date && (
        <Fragment>
          <div className="p-30">
            <p className="font-14 font-bold">보여지는 시작 날짜</p>
            <input
              type="date"
              value={adminProgram.custom_date_start}
              onChange={(e) => {
                const dateString = e.target.value;
                if (dateString) {
                  adminProgram.custom_date_start = e.target.value;
                  handleEdit();
                }
              }}
              className="width-100-percentage"
            />
          </div>
          <div className="p-30">
            <p className="font-14 font-bold">보여지는 종료 날짜</p>
            <input
              type="date"
              value={adminProgram.custom_date_end}
              onChange={(e) => {
                const dateString = e.target.value;
                if (dateString) {
                  adminProgram.custom_date_end = e.target.value;
                  handleEdit();
                }
              }}
              className="width-100-percentage"
            />
          </div>
        </Fragment>
      )}
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton
            onClick={() => {
              adminProgram.dates = [
                ...adminProgram.dates,
                formatDashedDate(new Date()),
              ];
              handleEdit();
            }}
          >
            날짜 추가
          </CustomButton>
        </div>
        {adminProgram.dates.map((date, index) => (
          <div className="mt-10" key={index}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <p className="font-14 font-bold">프로그램 날짜</p>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    if (dateString) {
                      adminProgram.dates[index] = dateString;
                      handleEdit();
                    }
                  }}
                  className="width-100-percentage"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  onClick={() => {
                    adminProgram.dates.splice(index, 1);
                    handleEdit();
                  }}
                >
                  삭제
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
      <div className="p-30">
        <CustomText
          label="시작 시간 (시) (24단위)"
          name="starting_hour"
          type="number"
          width="100%"
          defaultValue={adminProgram.starting_hour}
          handleBlur={(e) => {
            adminProgram.starting_hour = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="시작 시간 (분)"
          name="starting_minute"
          type="number"
          width="100%"
          defaultValue={adminProgram.starting_minute}
          handleBlur={(e) => {
            adminProgram.starting_minute = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="프로그램 진행 시간(분)"
          name="minutes"
          type="number"
          width="100%"
          defaultValue={adminProgram.minutes}
          handleBlur={(e) => {
            adminProgram.minutes = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="최소 인원"
          name="min_people"
          type="number"
          width="100%"
          defaultValue={adminProgram.min_people}
          handleBlur={(e) => {
            adminProgram.min_people = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="정원"
          name="max_people"
          type="number"
          width="100%"
          defaultValue={adminProgram.max_people}
          handleBlur={(e) => {
            adminProgram.max_people = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="별도 안내"
          name="guidance"
          type="text"
          width="100%"
          height="100px"
          multiline
          defaultValue={adminProgram.guidance}
          handleBlur={(e) => {
            adminProgram.guidance = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <TutorComponent
          program_id={adminProgram.id}
          main_tutor_id={adminProgram.main_tutor_id}
          sub_tutor_id={adminProgram.sub_tutor_id}
          trainee_tutor_id={adminProgram.trainee_tutor_id}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="튜터의 한마디"
          name="tutor_speech"
          type="text"
          width="100%"
          height="100px"
          multiline
          defaultValue={adminProgram.tutor_speech}
          handleBlur={(e) => {
            adminProgram.tutor_speech = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <ProgramBookComponent adminProgram={adminProgram} />
      </div>
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton onClick={handleAddMedia}>영상매체 추가</CustomButton>
        </div>
        {adminProgram.medias.map((media) => (
          <Card variant="outlined" className="mt-10 p-30" key={media.id}>
            <div className="d-flex justify-content-flex-end">
              <CustomButton onClick={() => handleDeleteMedia(media.id)}>
                삭제
              </CustomButton>
            </div>
            <div className="mt-10">
              <Grid container spacing={2}>
                <Grid item xs>
                  <img
                    src={media.image}
                    alt=""
                    className="width-100-percentage"
                  />
                </Grid>
                <Grid item>
                  <CustomImageUploadButton
                    fileChangeCallback={(file) => {
                      postImageFile(file).then((image) => {
                        media.image_id = image.id;
                        handleEditMedia(media);
                      });
                    }}
                  >
                    이미지 선택
                  </CustomImageUploadButton>
                </Grid>
              </Grid>
            </div>
            <div className="mt-10">
              <CustomText
                label="영상매체 이름"
                name="book_name"
                type="text"
                width="100%"
                defaultValue={media.name}
                handleBlur={(e) => {
                  media.name = e.target.value;
                  handleEditMedia(media);
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="설명"
                name="detail"
                type="text"
                width="100%"
                multiline
                height="100px"
                defaultValue={media.detail}
                handleBlur={(e) => {
                  media.detail = e.target.value;
                  handleEditMedia(media);
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="링크"
                name="link"
                type="text"
                width="100%"
                defaultValue={media.link}
                handleBlur={(e) => {
                  media.link = e.target.value;
                  handleEditMedia(media);
                }}
              />
            </div>
          </Card>
        ))}
      </div>
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton onClick={handleAddExperimental}>
            체험매체 추가
          </CustomButton>
        </div>
        {adminProgram.experimentals.map((experimental) => (
          <Card variant="outlined" className="mt-10 p-30" key={experimental.id}>
            <div className="d-flex justify-content-flex-end">
              <CustomButton
                onClick={() => handleDeleteExperimental(experimental.id)}
              >
                삭제
              </CustomButton>
            </div>
            <div className="mt-10">
              <Grid container spacing={2}>
                <Grid item xs>
                  <img
                    src={experimental.image}
                    alt=""
                    className="width-100-percentage"
                  />
                </Grid>
                <Grid item>
                  <CustomImageUploadButton
                    fileChangeCallback={(file) => {
                      postImageFile(file).then((image) => {
                        experimental.image_id = image.id;
                        handleEditExperimental(experimental);
                      });
                    }}
                  >
                    이미지 선택
                  </CustomImageUploadButton>
                </Grid>
              </Grid>
            </div>
            <div className="mt-10">
              <CustomText
                label="체험매체 이름"
                name="book_name"
                type="text"
                width="100%"
                defaultValue={experimental.name}
                handleBlur={(e) => {
                  experimental.name = e.target.value;
                  handleEditExperimental(experimental);
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="설명"
                name="detail"
                type="text"
                width="100%"
                multiline
                height="100px"
                defaultValue={experimental.detail}
                handleBlur={(e) => {
                  experimental.detail = e.target.value;
                  handleEditExperimental(experimental);
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="링크"
                name="link"
                type="text"
                width="100%"
                defaultValue={experimental.link}
                handleBlur={(e) => {
                  experimental.link = e.target.value;
                  handleEditExperimental(experimental);
                }}
              />
            </div>
          </Card>
        ))}
      </div>
      <div className="p-30">
        <CustomText
          label="이전 가격"
          name="prev_price"
          type="number"
          width="100%"
          defaultValue={adminProgram.prev_price}
          handleBlur={(e) => {
            adminProgram.prev_price = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="가격"
          name="price"
          type="number"
          width="100%"
          defaultValue={adminProgram.price}
          handleBlur={(e) => {
            adminProgram.price = parseInt(e.target.value) || 0;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomText
          label="가격 설명"
          name="price_description"
          type="text"
          width="100%"
          defaultValue={adminProgram.price_description}
          handleBlur={(e) => {
            adminProgram.price_description = e.target.value;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomCheck
          label="할인 정보"
          checkItems={templates
            .filter((template) => template.meta.type === "discount")
            .map((template) => ({
              label: `${template.meta.title}${(() => {
                const index = adminProgram.discount_template_ids.findIndex(
                  (discount_template_id) => discount_template_id === template.id
                );
                if (index === -1) {
                  return "";
                }
                return ` (${index + 1})`;
              })()}`,
              id: template.id,
              checked: adminProgram.discount_template_ids.includes(
                template.id as number
              ),
            }))}
          handleChange={(checkItemId: any, checked: boolean) => {
            if (!checked) {
              adminProgram.discount_template_ids =
                adminProgram.discount_template_ids.filter(
                  (discount_template_id) => discount_template_id !== checkItemId
                );
            } else {
              adminProgram.discount_template_ids = [
                ...adminProgram.discount_template_ids,
                checkItemId,
              ];
            }
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomCheck
          label="환불 규정"
          checkItems={templates
            .filter((template) => template.meta.type === "refund")
            .map((template) => ({
              label: `${template.meta.title}${(() => {
                const index = adminProgram.refund_template_ids.findIndex(
                  (refund_template_id) => refund_template_id === template.id
                );
                if (index === -1) {
                  return "";
                }
                return ` (${index + 1})`;
              })()}`,
              id: template.id,
              checked: adminProgram.refund_template_ids.includes(
                template.id as number
              ),
            }))}
          handleChange={(checkItemId: any, checked: boolean) => {
            if (!checked) {
              adminProgram.refund_template_ids =
                adminProgram.refund_template_ids.filter(
                  (refund_template_id) => refund_template_id !== checkItemId
                );
            } else {
              adminProgram.refund_template_ids = [
                ...adminProgram.refund_template_ids,
                checkItemId,
              ];
            }
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomCheck
          label="FAQ"
          checkItems={templates
            .filter((template) => template.meta.type === "faq")
            .map((template) => ({
              label: `${template.meta.title}${(() => {
                const index = adminProgram.faq_template_ids.findIndex(
                  (faq_template_id) => faq_template_id === template.id
                );
                if (index === -1) {
                  return "";
                }
                return ` (${index + 1})`;
              })()}`,
              id: template.id,
              checked: adminProgram.faq_template_ids.includes(
                template.id as number
              ),
            }))}
          handleChange={(checkItemId: any, checked: boolean) => {
            if (!checked) {
              adminProgram.faq_template_ids =
                adminProgram.faq_template_ids.filter(
                  (faq_template_id) => faq_template_id !== checkItemId
                );
            } else {
              adminProgram.faq_template_ids = [
                ...adminProgram.faq_template_ids,
                checkItemId,
              ];
            }
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomSelect
          label="혜택"
          name="benefit_template"
          items={[
            {
              label: "선택 안함",
              value: 0,
              disabled: true,
            },
            ...templates
              .filter((template) => template.meta.type === "benefit")
              .map((template) => ({
                label: template.meta.title,
                value: template.id!,
              })),
          ]}
          value={templates.length > 0 ? adminProgram.benefit_template_id : 0}
          handleChange={(value) => {
            adminProgram.benefit_template_id = value as number;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomCheck
          label="프로그램 세부유형"
          checkItems={programTypeDetailOptions.map((option) => ({
            label: option.label,
            id: option.id as number,
            checked: adminProgram.program_type_detail_ids.includes(
              option.id as number
            ),
          }))}
          handleChange={(checkItemId: any, checked: boolean) => {
            if (!checked) {
              adminProgram.program_type_detail_ids =
                adminProgram.program_type_detail_ids.filter(
                  (program_group_id) => program_group_id !== checkItemId
                );
            } else {
              adminProgram.program_type_detail_ids = [
                ...adminProgram.program_type_detail_ids,
                checkItemId,
              ];
            }
            handleEdit();
          }}
        />
      </div>
      <div className="p-30">
        <CustomSelect
          label="프로그램 라벨링"
          name="program_label"
          items={[
            {
              label: "선택 안함",
              value: 0,
            },
            ...programLabelOptions.map((option) => ({
              label: option.label,
              value: option.id!,
            })),
          ]}
          value={
            programLabelOptions.length > 0
              ? adminProgram.program_label_option_id
              : 0
          }
          handleChange={(value) => {
            adminProgram.program_label_option_id = value as number;
            handleEdit();
          }}
        />
      </div>
      <div className="p-30 d-flex justify-content-space-between align-items-center">
        <div>
          {adminProgram.pdf_file.location !== "" && (
            <a
              href={adminProgram.pdf_file.location}
              style={{ textDecoration: "none" }}
            >
              {adminProgram.pdf_file.filename}
            </a>
          )}
        </div>
        <div>
          <input
            type="file"
            hidden
            accept="application/pdf"
            ref={setInputRef}
            onChange={(e) => {
              const target = e.target;
              if (target.files && target.files.length > 0) {
                const file = target.files[0];
                postFile(file.name).then(({ id: fileId }) => {
                  putFile(fileId, file).then(() => {
                    adminProgram.pdf_file_id = fileId;
                    handleEdit();
                  });
                });
              }
            }}
          />
          <CustomButton
            onClick={() => {
              if (inputRef) {
                inputRef.click();
              }
            }}
            style={{ width: "auto" }}
          >
            PDF 업로드
          </CustomButton>
        </div>
      </div>
      <div className="p-30">
        <div className="d-flex justify-content-flex-end">
          <CustomButton
            onClick={() => {
              adminProgram.events = [
                ...adminProgram.events,
                {
                  image_id: 0,
                  title: "",
                  image: "",
                  content: "",
                },
              ];
              handleEdit();
            }}
          >
            이벤트 추가
          </CustomButton>
        </div>
        {adminProgram.events.map((event, index) => (
          <Card variant="outlined" className="mt-10 p-30" key={index}>
            <div className="d-flex justify-content-flex-end">
              <CustomButton
                onClick={() => {
                  adminProgram.events.splice(index, 1);
                  handleEdit();
                }}
              >
                삭제
              </CustomButton>
            </div>
            <div className="mt-10">
              <CustomText
                label="제목"
                name="title"
                type="text"
                width="100%"
                multiline
                height="100px"
                defaultValue={event.title}
                handleBlur={(e) => {
                  event.title = e.target.value;
                  handleEdit();
                }}
              />
            </div>
            <div className="mt-10">
              <Grid container spacing={2}>
                <Grid item xs>
                  <img
                    src={event.image}
                    alt=""
                    className="width-100-percentage"
                  />
                </Grid>
                <Grid item>
                  <CustomImageUploadButton
                    fileChangeCallback={(file) => {
                      postImageFile(file).then((image) => {
                        event.image_id = image.id;
                        handleEdit();
                      });
                    }}
                  >
                    도서 사진 선택
                  </CustomImageUploadButton>
                </Grid>
              </Grid>
            </div>
            <div className="mt-10">
              <CustomText
                label="내용"
                name="content"
                type="text"
                width="100%"
                multiline
                height="100px"
                defaultValue={event.content}
                handleBlur={(e) => {
                  event.content = e.target.value;
                  handleEdit();
                }}
              />
            </div>
          </Card>
        ))}
      </div>
      <CustomDialog open={openDelete}>
        <p className="font-header">정말로 삭제 하시겠습니까?</p>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CustomButton onClick={handleDelete}>삭제</CustomButton>
          </Grid>
          <Grid item>
            <CustomButton onClick={() => setOpenDelete(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
    </Fragment>
  );
}

interface ProgramBook {
  id: number;
  program_id: number;
  book_id: number;
}

interface ProgramMedia {
  id: number;
  image: string;
  image_id: number;
  name: string;
  detail: string;
  link: string;
}

interface ProgramExperimental {
  id: number;
  image: string;
  image_id: number;
  name: string;
  detail: string;
  link: string;
}

interface ProgramEvent {
  title: string;
  image_id: number;
  image: string;
  content: string;
}

interface AdminProgram {
  id: number;
  program_group_ids: number[];
  program_image: string;
  program_image_id: number;
  program_header: string;
  program_name: string;
  program_description: string;
  program_description_internal: string;
  location: string;
  dates: string[];
  starting_hour: number;
  starting_minute: number;
  min_people: number;
  max_people: number;
  visualize: boolean;
  minutes: number;
  program_type_ids: number[];
  program_genre_ids: number[];
  program_detail_which_members: string;
  program_progress_programs: string[];
  program_detail_learns: string[];
  program_progresses: string[];
  books: ProgramBook[];
  medias: ProgramMedia[];
  experimentals: ProgramExperimental[];
  prev_price: number;
  price: number;
  price_description: string;
  discount_template_ids: number[];
  refund_template_ids: number[];
  faq_template_ids: number[];
  benefit_template_id: number;
  main_tutor_id: number;
  sub_tutor_id: number;
  trainee_tutor_id: number;
  tutor_speech: string;
  pdf_file: {
    filename: string;
    location: string;
  };
  pdf_file_id: number;
  guidance: string;
  events: ProgramEvent[];
  program_type_detail_ids: number[];
  program_label_option_id: number;
  classic: boolean;
  custom_date: boolean;
  custom_date_start: string;
  custom_date_end: string;
  banner_image_id: number;
  banner_image: string;
}

export function useAdminPrograms(programGroupId: number) {
  const [adminPrograms, setAdminPrograms] = useState<AdminProgram[]>([]);

  const refreshAdminPrograms = useCallback(() => {
    return fetchDefaultWithCredential(
      `/program/list/admin?program_group_id=${programGroupId}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setAdminPrograms);
    });
  }, [programGroupId]);

  useEffect(() => {
    refreshAdminPrograms();
  }, [refreshAdminPrograms]);

  return { adminPrograms, refreshAdminPrograms };
}

interface AdminProgramsContextProps {
  refreshAdminPrograms: () => Promise<void>;
  programTypeOptions: Option[];
  programGenreOptions: Option[];
  programTypeDetailOptions: Option[];
  programLabelOptions: Option[];
  templates: Template[];
}

const AdminProgramsContext = React.createContext(
  {} as AdminProgramsContextProps
);

interface ProgramRegistrationComponentProps {
  currentProgramGroup: ProgramGroup;
}

function ProgramRegistrationComponent({
  currentProgramGroup,
}: ProgramRegistrationComponentProps) {
  const { adminPrograms, refreshAdminPrograms } = useAdminPrograms(
    currentProgramGroup.id
  );
  const { refreshProgramGroups } = useContext(ProgramGroupsContext);
  const { options: programTypeOptions } = useOptions("program_type");
  const { options: programGenreOptions } = useOptions("program_genre");
  const { options: programTypeDetailOptions } = useOptions(
    "program_type_detail"
  );
  const { options: programLabelOptions } = useOptions("program_label");
  const { templates } = useTemplates();

  const [currentProgramId, setCurrentProgramId] = useState<number>(0);

  const currentProgram = adminPrograms.find(
    (adminProgram) => adminProgram.id === currentProgramId
  );

  const handleAdd = () => {
    return fetchDefaultWithCredential(`/program`, "POST", {
      program_group_id: currentProgramGroup.id,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(refreshProgramGroups).then(refreshAdminPrograms);
    });
  };

  if (
    !programTypeOptions ||
    !programGenreOptions ||
    !programTypeDetailOptions ||
    !programLabelOptions
  )
    return null;

  return (
    <Card className="p-30">
      <div className="d-flex justify-content-space-between">
        <div className="font-header">프로그램 등록</div>
        {currentProgramGroup.id !== 0 && (
          <CustomButton onClick={handleAdd}>추가</CustomButton>
        )}
      </div>
      <div className="mt-10">
        <CustomSelect
          name="program_name"
          label="프로그램 명"
          items={[
            {
              label: "선택 안함",
              value: 0,
              disabled: true,
            },
            ...adminPrograms.map((adminProgram) => ({
              label: adminProgram.program_name,
              value: adminProgram.id!,
            })),
          ]}
          value={currentProgramId}
          handleChange={(value) => setCurrentProgramId(value as number)}
        />
      </div>
      {currentProgram && (
        <AdminProgramsContext.Provider
          value={{
            refreshAdminPrograms,
            programTypeOptions,
            programGenreOptions,
            programTypeDetailOptions,
            programLabelOptions,
            templates,
          }}
        >
          <IndividualProgramComponent
            key={currentProgram.id}
            adminProgram={currentProgram}
          />
        </AdminProgramsContext.Provider>
      )}
    </Card>
  );
}

interface ProgramGroupSelectionComponentProps {
  currentProgramGroup: ProgramGroup | null;
  setCurrentProgramGroup: (currentProgramGroup: ProgramGroup) => void;
}

function ProgramGroupSelectionComponent({
  currentProgramGroup,
  setCurrentProgramGroup,
}: ProgramGroupSelectionComponentProps) {
  const { programGroups } = useContext(ProgramGroupsContext);

  return (
    <Fragment>
      {programGroups.map((programGroup, index) => (
        <Card
          className="mb-30 p-30 cursor-pointer"
          key={index}
          onClick={() => setCurrentProgramGroup(programGroup)}
          style={{
            backgroundColor:
              currentProgramGroup?.id === programGroup.id
                ? "lightgray"
                : "inherit",
          }}
        >
          <div className="font-header">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                {programGroup.name}
              </Grid>
              <Grid item>({programGroup.program_count})</Grid>
            </Grid>
          </div>
        </Card>
      ))}
    </Fragment>
  );
}

function useProgramGroups() {
  const [programGroups, setProgramGroups] = useState<ProgramGroup[]>([]);

  const refreshProgramGroups = useCallback(() => {
    return fetchDefaultWithCredential("/program/groups", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setProgramGroups);
    });
  }, []);

  useEffect(() => {
    refreshProgramGroups();
  }, [refreshProgramGroups]);

  return { programGroups, refreshProgramGroups };
}

interface ProgramGroupsContextProps {
  programGroups: ProgramGroup[];
  refreshProgramGroups: () => Promise<void>;
}

const ProgramGroupsContext = React.createContext(
  {} as ProgramGroupsContextProps
);

function AdminPageProgram() {
  const { programGroups, refreshProgramGroups } = useProgramGroups();
  const [currentProgramGroup, setCurrentProgramGroup] =
    useState<ProgramGroup | null>(null);
  return (
    <div className="program-container">
      <ProgramGroupsContext.Provider
        value={{ programGroups, refreshProgramGroups }}
      >
        <Grid container columnSpacing={2}>
          <Grid item sm={4} xs={12}>
            <ProgramGroupComponent />
          </Grid>
          <Grid item sm={4} xs={12}>
            <ProgramGroupSelectionComponent
              currentProgramGroup={currentProgramGroup}
              setCurrentProgramGroup={setCurrentProgramGroup}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            {currentProgramGroup && (
              <ProgramRegistrationComponent
                currentProgramGroup={currentProgramGroup}
                key={currentProgramGroup.id}
              />
            )}
          </Grid>
        </Grid>
      </ProgramGroupsContext.Provider>
    </div>
  );
}

export default AdminPageProgram;
