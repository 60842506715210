import React, { useRef } from "react";
import Swiper, {
  ReactIdSwiperChildren,
  ReactIdSwiperProps,
} from "react-id-swiper";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

interface PropType {
  children: ReactIdSwiperChildren;
  slides?: number;
  onSlideChange?: (index: number) => void;
  noNav?: boolean;
  swiperProps?: ReactIdSwiperProps;
}

function CustomSwiper({
  children,
  slides,
  onSlideChange,
  noNav,
  swiperProps,
}: PropType) {
  const swiperRef = useRef<HTMLDivElement | null>(null);
  return (
    <Swiper
      navigation={{
        nextEl: noNav ? null : ".swiper-button-next",
        prevEl: noNav ? null : ".swiper-button-prev",
      }}
      slidesPerView={slides}
      ref={swiperRef}
      on={{
        slideChange: (swiper) => {
          if (onSlideChange) {
            onSlideChange(swiper.activeIndex);
          }
        },
      }}
      {...swiperProps}
    >
      {children}
    </Swiper>
  );
}

export default CustomSwiper;
